import React, { useEffect, useState } from 'react';
import classes from './Stats.module.scss';
import crossIcon from '../../assets/png/crossIcon.png';
import arrowLeft from '../../assets/png/arrowLeft.png';
import arrowRight from '../../assets/png/arrowRight.png';
import shoppingBag from '../../assets/png/shoppingBag.png';
import GaugeChart from 'react-gauge-chart';
import { Carousel, CarouselItem } from 'react-bootstrap';
import StatsInfo from '../../components/statsInfo/StatsInfo';
import Button from '../../components/common/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getMonthName } from '../../utils/helpers';
import { COLORS, DummyMngrSummary, MONTHS, ROLES } from '../../utils/constants';
import LogoutModal from '../../components/common/LogoutModal/LogoutModal';
import {
  getGoalsByMonthAndYearAsync,
  getUserGoalsAsyncThunk,
  previousMonthGoalsAsyncApiCall,
} from '../../redux/actions/goalsByMonthAndYear.action';
import {
  getCities,
  getStores,
  postGoalsApiCall,
  getGmMessage,
  saveGmMessage,
  setStoreSalesTarget,
  setCountrySalesTarget,
} from '../../services/general.services';
import { toast } from 'react-toastify';
import Modal from '../../components/common/Modal/Modal';
import OneToOneSalesAmountModal from './OneToOneSalesAmountModal';
import LogoutSvg from '../../assets/svg/LogoutSvg';
import useLogout from '../../utils/hooks/useLogout';
import Loader from '../../components/common/Loader/Loader';
import { StateInfoTypes } from '../../utils/constants';
import UndoSvg from '../../assets/svg/UndoSvg';
import TargetBox from '../../components/TargetBox/TargetBox';
import CalendarSvg from '../../assets/svg/CalendarIcon';
import ExpansionPanel from '../../components/ExpansionPanel/ExpansionPanel';
import { Input } from '../../components/common/Input/Input';
import ShoppingBagSvg from '../../assets/svg/ShoppingBagSvg';
import LocationSvg from '../../assets/svg/LocationSvg';
import UserSvg from '../../assets/svg/UserSvg';
import { isNumericValue } from '../../utils/helpers';
import Select from '../../components/common/ReactSelect/ReactSelect';
import {
  getStoreSalesTargetAsync,
  previousMonthStoreSalesTarget,
} from '../../redux/actions/storeSalesTarget.action';
import {
  getCountrySalesTargetAsync,
  previousMonthCountrySalesTargetAsync,
} from '../../redux/actions/countrySalesTarget.action';
import ArrownLeftSvg from '../../assets/svg/ArrowLeftSvg';
import ArrownRightSvg from '../../assets/svg/ArrowRightSvg';
import UsersSvg from '../../assets/svg/UsersSvg';
import SalesSummary from '../../components/SalesSummary/SalesSummary';
import { fetchSalesTargetSummaryAsync } from '../../redux/actions/summary.action';
import { setSummary } from '../../redux/reducers/summaryReducer';
import { stat } from 'fs';

const Years = ['2021', '2022', '2023', '2024'];

const Stats = () => {
  const dispatch = useDispatch<any>();
  const user = useSelector((state: any) => state?.root?.user?.user);
  const { isLoading: loadingSummary, summary } = useSelector(
    (state: any) => state?.root?.summary
  );
  const isEmployee = user?.role === ROLES.Employee;
  const isManager = user?.role === ROLES.Manager;
  const isGM = user?.role === ROLES.GM;
  const { stats } = useSelector((state: any) => state?.root?.stats);
  const {
    goals,
    isLoading,
    averageOneToOneSales,
    previousMonthAverageOneToOneSales,
  } = useSelector((state: any) => state?.root?.goals);
  const { previousMonthGoals } = useSelector(
    (state: any) => state?.root?.goals
  );

  const { logout } = useLogout();
  const [users, setUsers] = useState<any>([]);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [stores, setStores] = useState<any>([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [gmMsgError, setGmMsgError] = useState<string | null>(null);
  const [activeYear, setActiveYear] = useState(3);

  const [activeMonth, setActiveMonth] = useState(stats?.current_month);
  console.log('activeMonth->', activeMonth);
  const [presentationCount, setPresentationCount] = useState(0);
  const [productSaleCount, setProductSaleCount] = useState(0);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [oneToOneSaleCount, setOneToOneSaleCount] = useState(0);
  const [oneToOneSaleAmountCount, setOneToOneSaleAmountCount] = useState(0);
  const [totalSalesAmmount, setTotalSalesAmmount] = useState<any>('');
  const [avgOneToOneSalesAmount, setAvgOneToOneSalesAmount] = useState<any>('');
  const [pekabooAmount, setPekabooAmount] = useState<any>('');
  const [pekabooTarget, setPekabooTarget] = useState<any>('');
  const [oneToOneAmount, setOneToOneAmount] = useState<any>('');
  const [oneToOneTarget, setOneToOneTarget] = useState<any>('');

  const [gmMessage, setGmMessage] = useState('');

  const [showOneToOneSaleAmountModal, setShowOneToOneSaleAmountModal] =
    useState(false);

  // useEffect(() => {
  //   if (isManager || isGM) {
  //     setTotalSalesAmmount(goals?.total_sales_target);
  //   } else if (isEmployee) {
  //     setTotalSalesAmmount(goals?.total_sale?.amount_target);
  //     setPekabooAmount(goals?.product_presentation?.amount_target);
  //     setPekabooTarget(goals?.product_presentation?.click_target);
  //     setOneToOneAmount(goals?.one_to_one_sale?.amount_target);
  //     setOneToOneTarget(goals?.one_to_one_sale?.click_target);
  //   }
  // }, [goals]);

  // useEffect(() => {
  //   if (isGM) {
  //     setAvgOneToOneSalesAmount(
  //       averageOneToOneSales?.average_one_to_one_sales_target
  //     );
  //   }
  // }, [averageOneToOneSales]);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      const cls = e.target.className.toLowerCase();
      const isSummaryClicked = cls.includes('summary');
      const isInputClicked = cls.includes('input');
      if (!isSummaryClicked && !isInputClicked) {
        setSummaryData([]);
        setShowSummary(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isManager || isGM) {
      const gmData = {
        country_id: user?.country_id,
        store_id: selectedStore,
      };
      const managerData = { store_id: user?.store_id };
      const params = {
        month: activeMonth,
        year: '2024',
        ...(isGM && gmData),
        ...(isManager && managerData),
      };
      dispatch(fetchSalesTargetSummaryAsync(params));
    }
  }, [selectedStore, activeMonth]);

  useEffect(() => {
    if (isManager || isGM) {
      if (showSummary) {
        setSummaryData(summary);
      }
    }
  }, [summary]);

  useEffect(() => {
    if (isGM) {
      getCities()
        .then((res) => {
          setCities(res?.data?.cities);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (isGM) {
      getGmMessage().then((res: any) => {
        setGmMessage(res?.data?.gm_message);
      });
    }
  }, []);

  useEffect(() => {
    if (isGM) {
      let data = {
        city_id: selectedCity ?? '',
      };
      setSelectedStore(null);
      getStores(data)
        .then((res) => {
          setStores(res?.data?.stores);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedCity]);

  useEffect(() => {
    if (isEmployee) {
      const currentMonthData = {
        product_id: user?.active_product_id,
        month: stats?.current_month,
        year: '2024',
      };

      const previousMonthData = {
        product_id: user?.active_product_id,
        month: stats?.current_month - 1,
        year: '2024',
      };

      dispatch(getGoalsByMonthAndYearAsync(currentMonthData));
      dispatch(previousMonthGoalsAsyncApiCall(previousMonthData));
    }
  }, []);

  useEffect(() => {
    if (isManager) {
      const currentMonthData = {
        store_id: user?.store_id,
        month: stats?.current_month,
        year: '2024',
      };

      const previousMonthData = {
        store_id: user?.store_id,
        month: stats?.current_month - 1,
        year: '2024',
      };

      dispatch(getStoreSalesTargetAsync(currentMonthData));
      dispatch(previousMonthStoreSalesTarget(previousMonthData));
    }
  }, []);

  useEffect(() => {
    if (isGM) {
      const currentMonthData = {
        country_id: user?.country_id,
        month: stats?.current_month,
        year: '2024',
      };

      const previousMonthData = {
        country_id: user?.country_id,
        month: stats?.current_month - 1,
        year: '2024',
      };

      dispatch(getStoreSalesTargetAsync(currentMonthData));
      dispatch(previousMonthStoreSalesTarget(previousMonthData));
    }
  }, []);

  useEffect(() => {
    if (isGM) {
      const currentMonthData = {
        month: stats?.current_month,
        year: '2024',
      };

      const previousMonthData = {
        month: stats?.current_month - 1,
        year: '2024',
      };

      dispatch(getCountrySalesTargetAsync(currentMonthData));
      dispatch(previousMonthCountrySalesTargetAsync(previousMonthData));
    }
  }, []);

  useEffect(() => {
    setOneToOneSaleAmountCount(0);
  }, [goals]);

  const handleNextMonth = () => {
    if (isEmployee) {
      const data = {
        product_id: user?.active_product_id,
        month: activeMonth === 12 ? 1 : activeMonth + 1,
        year: '2024',
      };
      const previousMonthData = {
        product_id: user?.active_product_id,
        month: activeMonth === 1 ? 12 : activeMonth,
        year: '2024',
      };
      dispatch(getGoalsByMonthAndYearAsync(data));
      dispatch(previousMonthGoalsAsyncApiCall(previousMonthData));
    } else if (isManager) {
      const data = {
        store_id: user?.store_id,
        month: activeMonth === 12 ? 1 : activeMonth + 1,
        year: '2024',
      };
      const previousMonthData = {
        store_id: user?.store_id,
        month: activeMonth === 1 ? 12 : activeMonth,
        year: '2024',
      };
      dispatch(getStoreSalesTargetAsync(data));
      dispatch(previousMonthStoreSalesTarget(previousMonthData));
    } else if (isGM) {
      const currentMonthData = {
        country_id: user?.country_id,
        month: activeMonth === 12 ? 1 : activeMonth + 1,
        year: '2024',
        city_id: selectedCity ?? '',
        store_id: selectedStore ?? '',
      };

      const previousMonthData = {
        country_id: user?.country_id,
        month: activeMonth === 1 ? 12 : activeMonth,
        year: '2024',
        city_id: selectedCity ?? '',
        store_id: selectedStore ?? '',
      };

      dispatch(getStoreSalesTargetAsync(currentMonthData));
      dispatch(previousMonthStoreSalesTarget(previousMonthData));
      dispatch(getCountrySalesTargetAsync(currentMonthData));
      dispatch(previousMonthCountrySalesTargetAsync(previousMonthData));
    }
  };

  const handlePreviousMonth = () => {
    if (isEmployee) {
      const data = {
        product_id: user?.active_product_id,
        month: activeMonth == 1 ? 12 : activeMonth - 1,
        year: '2024',
      };

      const previousMonthData = {
        product_id: user?.active_product_id,
        month:
          activeMonth === 2 ? 12 : activeMonth === 1 ? 11 : activeMonth - 2,
        year: '2024',
      };
      dispatch(getGoalsByMonthAndYearAsync(data));
      dispatch(previousMonthGoalsAsyncApiCall(previousMonthData));
    } else if (isManager) {
      const data = {
        store_id: user?.store_id,
        month: activeMonth == 1 ? 12 : activeMonth - 1,
        year: '2024',
      };

      const previousMonthData = {
        store_id: user?.store_id,
        month:
          activeMonth === 2 ? 12 : activeMonth === 1 ? 11 : activeMonth - 2,
        year: '2024',
      };
      dispatch(getStoreSalesTargetAsync(data));
      dispatch(previousMonthStoreSalesTarget(previousMonthData));
    } else if (isGM) {
      const currentMonthData = {
        country_id: user?.country_id,
        month: activeMonth == 1 ? 12 : activeMonth - 1,
        year: '2024',
        city_id: selectedCity ?? '',
        store_id: selectedStore ?? '',
      };

      const previousMonthData = {
        country_id: user?.country_id,
        month:
          activeMonth === 2 ? 12 : activeMonth === 1 ? 11 : activeMonth - 2,
        year: '2024',
        city_id: selectedCity ?? '',
        store_id: selectedStore ?? '',
      };

      dispatch(getStoreSalesTargetAsync(currentMonthData));
      dispatch(previousMonthStoreSalesTarget(previousMonthData));
      dispatch(getCountrySalesTargetAsync(currentMonthData));
      dispatch(previousMonthCountrySalesTargetAsync(previousMonthData));
    }
  };

  const handleSubmit = (
    value?: number,
    type?: string,
    amount?: number | null
  ) => {
    const params = {
      goals: [
        {
          product_id: user?.active_product_id,
          goal_type: 'product_presentation',
          target:
            type === StateInfoTypes.Presentaion
              ? value
              : pekabooTarget
              ? pekabooTarget
              : goals?.product_presentation?.click_target,
          amount:
            type === StateInfoTypes.Presentaion
              ? amount
              : pekabooAmount
              ? pekabooAmount
              : goals?.product_presentation?.amount_target_clean,
        },
        {
          product_id: user?.active_product_id,
          goal_type: 'one_to_one_sale',
          target:
            type === StateInfoTypes.OneToOneSales
              ? value
              : oneToOneTarget
              ? oneToOneTarget
              : goals?.one_to_one_sale?.click_target,
          amount:
            type === StateInfoTypes.OneToOneSales
              ? amount
              : oneToOneAmount
              ? oneToOneAmount
              : goals?.one_to_one_sale?.amount_target,
        },
        {
          product_id: user?.active_product_id,
          goal_type: 'total_sale',
          target:
            type === StateInfoTypes.TotalSales
              ? value
              : goals?.total_sale?.click_target,
          amount:
            type === StateInfoTypes.TotalSales
              ? amount
              : totalSalesAmmount
              ? totalSalesAmmount
              : goals?.total_sale?.amount_target,
        },
      ],
      month: activeMonth,
      year: '2024',
    };
    postGoalsApiCall(params)
      .then((res) => {
        toast.success(res?.data?.message);
        setTotalSalesAmmount('');
        setPekabooAmount('');
        setPekabooTarget('');
        setOneToOneAmount('');
        setOneToOneTarget('');
        const data = {
          product_id: user?.active_product_id,
          month: activeMonth,
          year: '2024',
        };
        dispatch(getGoalsByMonthAndYearAsync(data));
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handleAmountSubmit = (values: any) => {
    if (values?.amount) {
      // setOneToOneSaleAmountCount(values?.amount);
      handleSubmit(0, '', values?.amount);
      setShowOneToOneSaleAmountModal(false);
    }
  };

  const handleLogout = () => {
    setLogoutModalOpen(true);
  };

  const handleCityChange = (selectedOption: any) => {
    setSelectedCity(selectedOption.value);
    setSelectedStore(null); // Since the store filter has been set to null, we will not send store to API to fetch updated records...
    if (isGM) {
      const currentMonthData = {
        store_id: null, // Reason mentioned above
        city_id: selectedOption?.value,
        country_id: user?.country_id,
        month: activeMonth,
        year: Years[activeYear],
      };

      const previousMonthData = {
        store_id: null, // Reason mentioned above
        city_id: selectedOption?.value,
        country_id: user?.country_id,
        month: activeMonth - 1,
        year: Years[activeYear],
      };

      dispatch(getStoreSalesTargetAsync(currentMonthData));
      dispatch(previousMonthStoreSalesTarget(previousMonthData));
      dispatch(getCountrySalesTargetAsync(currentMonthData));
      dispatch(previousMonthCountrySalesTargetAsync(previousMonthData));
    }
  };

  const handleStoreChange = (selectedOption: any) => {
    setSelectedStore(selectedOption.value);
    if (isGM) {
      const currentMonthData = {
        store_id: selectedOption.value,
        city_id: selectedCity ?? null,
        country_id: user?.country_id,
        month: activeMonth,
        year: Years[activeYear],
      };

      const previousMonthData = {
        store_id: selectedOption.value,
        city_id: selectedCity ?? null,
        country_id: user?.country_id,
        month: activeMonth - 1,
        year: Years[activeYear],
      };

      dispatch(getStoreSalesTargetAsync(currentMonthData));
      dispatch(previousMonthStoreSalesTarget(previousMonthData));
      dispatch(getCountrySalesTargetAsync(currentMonthData));
      dispatch(previousMonthCountrySalesTargetAsync(previousMonthData));
    }
  };

  const handleSetStoreSalesTargetAmount = (data: any) => {
    setStoreSalesTarget(data)
      .then((res: any) => {
        toast.success(res?.data?.message);
        setTotalSalesAmmount('');
        dispatch(setSummary(res?.data?.response));
        setSummaryData(res?.data?.response);
        setShowSummary(true);
        let data = {};
        if (isManager) {
          //this is for manager scenario
          data = {
            store_id: user?.store_id,
            month: activeMonth,
            year: '2024',
          };
        } else {
          //this is for GM scenario
          data = {
            store_id: selectedStore,
            city_id: selectedCity,
            country_id: user?.country_id,
            month: activeMonth,
            year: '2024',
          };
        }

        dispatch(getStoreSalesTargetAsync(data));
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handleRegisterTotalSalesAmount = () => {
    if (isEmployee) {
      handleSubmit(0, StateInfoTypes.TotalSales, totalSalesAmmount);
    } else if (isManager) {
      const data = {
        store_id: user?.store_id,
        amount: totalSalesAmmount,
        month: activeMonth,
        year: '2024',
      };
      handleSetStoreSalesTargetAmount(data);
    } else {
      const data = {
        store_id: selectedStore,
        city_id: selectedCity,
        country_id: user?.country_id,
        amount: totalSalesAmmount,
        month: activeMonth,
        year: '2024',
      };
      handleSetStoreSalesTargetAmount(data);
    }
  };

  const handleRegisterPekabooAmount = () => {
    handleSubmit(
      goals?.product_presentation?.click_target,
      StateInfoTypes.Presentaion,
      pekabooAmount
    );
  };
  const handleRegisterPekabooTarget = () => {
    handleSubmit(
      pekabooTarget,
      StateInfoTypes.Presentaion,
      goals?.product_presentation?.amount_target_clean
    );
  };

  const handleRegisterOneToOneAmount = () => {
    handleSubmit(
      goals?.one_to_one_sale?.click_target,
      StateInfoTypes.OneToOneSales,
      oneToOneAmount
    );
  };
  const handleRegisterOneToOneTarget = () => {
    handleSubmit(
      oneToOneTarget,
      StateInfoTypes.OneToOneSales,
      goals?.one_to_one_sale?.amount_target
    );
  };

  const handleAvgSalesAmountChange = () => {
    const data = {
      month: activeMonth,
      year: '2024',
      amount: avgOneToOneSalesAmount,
    };
    setCountrySalesTarget(data)
      .then((res: any) => {
        toast.success(res?.data?.message);
        setAvgOneToOneSalesAmount('');
        console.log(res);
        const data = {
          month: activeMonth,
          year: '2024',
        };
        dispatch(getCountrySalesTargetAsync(data));
      })
      .catch((err: any) => {
        console.log('err', err);
      });
  };

  const getTitleForGm = () => {
    let title = user?.country_name;
    if (selectedCity) {
      title = cities?.find((city: any) => city.id === selectedCity)?.name;
    }
    if (selectedStore) {
      title = stores?.find((store: any) => store.id === selectedStore)?.name;
    }
    return title;
  };

  const hideSummary = () => {
    setSummaryData([]);
    setShowSummary(false);
  };

  return (
    <>
      <div className={classes.container}>
        <button className={classes.logout_btn} onClick={handleLogout}>
          Logout
          <LogoutSvg />
        </button>
        {/* <div className={classes.back_btn_wrapper} onClick={() => {}}>
          <UndoSvg />
          <p>Undo</p>
        </div> */}
        <div className={classes.header}>
          <p className={classes.heading_text}>Your targets</p>
        </div>
        {/* <div className={classes.guage_container}>
          <div className={classes.guage_class}>
            <GaugeChart
              className={classes.guage_class}
              arcWidth={0.08}
              percent={100}
              colors={['#00FAD9', '#FF7966', '#AD7BFF']}
              arcsLength={[1, 1, 1]}
              needleBaseColor="none"
              needleColor="none"
              hideText
            />
            <div className={classes.heading}>
              <div>
                {activeMonth === 1
                  ? getMonthName(12)
                  : getMonthName(activeMonth - 1)}
              </div>
            </div>
            <div className={classes.label1}>
              {previousMonthGoals?.product_sale?.target
                ? previousMonthGoals?.product_sale?.target
                : 0}
            </div>
            <div className={classes.label2}>
              {' '}
              {previousMonthGoals?.one_to_one_sale?.target
                ? previousMonthGoals?.one_to_one_sale?.target
                : 0}
            </div>
            <div className={classes.label3}>
              {previousMonthGoals?.product_presentation?.target
                ? previousMonthGoals?.product_presentation?.target
                : 0}
            </div>
          </div>
        </div> */}
        <div className={classes.month_container}>
          <div className={classes.month}>
            {activeMonth === 1
              ? getMonthName(12).toUpperCase()
              : getMonthName(activeMonth - 1).toUpperCase()}
          </div>
        </div>
        <div
          className={`${classes.targets} ${
            (isManager || isGM) && classes.in_center
          }`}
        >
          {(isEmployee || isManager || isGM) && (
            <TargetBox
              target={isGM && ''}
              amount={
                isManager || isGM
                  ? `€ ${previousMonthGoals?.total_sales_target}`
                  : `€ ${previousMonthGoals?.total_sale?.amount_target || 0}`
              }
              icon={<UserSvg />}
              targetStyles={
                isGM ? { color: 'white' } : { color: COLORS.carrot }
              }
              amountStyles={{ color: COLORS.carrot }}
              topBorderClassName={classes.carrot_top}
              heading={isEmployee ? 'SALES TARGET' : 'TOTAL SALES'}
            />
          )}
          {isEmployee && (
            <TargetBox
              target={`${previousMonthGoals?.product_presentation?.click_target} P`}
              amount={`${previousMonthGoals?.product_presentation?.amount_target_clean} U`}
              icon={<ShoppingBagSvg />}
              targetStyles={
                isGM ? { color: 'white' } : { color: COLORS.lightgreen }
              }
              amountStyles={{ color: COLORS.lightgreen }}
              topBorderClassName={classes.green_top}
              heading="PEKABOO"
            />
          )}
          {(isEmployee || isGM) && (
            <TargetBox
              target={
                isGM ? '' : previousMonthGoals?.one_to_one_sale?.click_target
              }
              amount={`€ ${
                isGM
                  ? previousMonthAverageOneToOneSales?.average_one_to_one_sales_target
                  : previousMonthGoals?.one_to_one_sale?.amount_target || 0
              }`}
              icon={<UsersSvg />}
              targetStyles={
                isGM ? { color: 'white' } : { color: COLORS.yellow }
              }
              amountStyles={{ color: COLORS.yellow }}
              topBorderClassName={classes.yellow_top}
              heading={isGM ? 'AVG. SALES' : 'OTO'}
            />
          )}
        </div>
        {isGM && (
          <div className={classes.select_buttons}>
            {/* <Select
              defaultValue="Select City"
              options={[{ id: null, name: 'All Cities' }, ...cities]}
              onChange={handleCityChange}
              value={selectedCity}
              mainClassName={classes.select_main}
              menuStyles={{
                left: '-44px',
              }}
            /> */}
            <Select
              defaultValue="Select Store"
              options={[{ id: null, name: 'All Stores' }, ...stores]}
              onChange={handleStoreChange}
              value={selectedStore}
              mainClassName={classes.select_main}
              menuStyles={{
                left: '-44px',
              }}
            />
            {/* <Select
            options={[{ id: null, name: 'All Staff' }, ...users]}
            onChange={handleUserChange}
            value={selectedUser}
          /> */}
          </div>
        )}
        <div className={classes.carousel_container}>
          <Carousel
            prevIcon={<ArrownLeftSvg onClick={handlePreviousMonth} />}
            nextIcon={<ArrownRightSvg onClick={handleNextMonth} />}
            slide={false}
            interval={null}
            indicators={false}
            defaultActiveIndex={activeMonth - 1}
            activeIndex={activeMonth - 1}
            onSelect={(newValue) => {
              setActiveMonth(newValue + 1);
            }}
            wrap={false}
          >
            {MONTHS?.map((month) => (
              <CarouselItem className={classes.item}>{month}</CarouselItem>
            ))}
          </Carousel>
        </div>

        <div className={classes.stats_info_container}>
          {(isEmployee || isManager || isGM) && (
            <ExpansionPanel
              title={
                isGM
                  ? getTitleForGm()
                  : isEmployee
                  ? user?.name
                  : user?.city_name
              }
              type={isGM && 'Sales target'}
              icon={<UserSvg />}
              typeColor={COLORS.carrot}
              // target={isManager ? '' : goals?.total_sale?.click_target}
              amount={
                isManager || isGM
                  ? `€${goals?.total_sales_target}`
                  : `€${goals?.total_sale?.amount_target}`
              }
              setShowSummary={setShowSummary}
              setSummaryData={setSummaryData}
              hideSummary={hideSummary}
            >
              {(closePanel: any) => {
                return (
                  <div className={classes.panel_content_container}>
                    <div className={classes.input_container}>
                      <Input
                        containerClassName={classes.input_main}
                        inputClassName={classes.input_field_left_aligned}
                        placeholder="0"
                        type="text"
                        value={`€ ${totalSalesAmmount}`}
                        onChange={(e: any) => {
                          e.stopPropagation();
                          const val = e.target.value.substring(1)?.trim();
                          const isNumericVal = isNumericValue(val);
                          if (isNumericVal) {
                            setTotalSalesAmmount(val);
                          }
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                    </div>
                    <div className={classes.btn_container}>
                      <Button
                        buttonClassName={classes.total_sales_btn}
                        text="Register Amount"
                        onClick={() => {
                          handleRegisterTotalSalesAmount();
                          closePanel();
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                    </div>
                  </div>
                );
              }}
            </ExpansionPanel>
          )}
          {(isManager || isGM) && (
            <SalesSummary
              summaryData={summaryData}
              showSummary={showSummary}
              hideSummary={hideSummary}
            />
          )}
          {isEmployee && (
            <ExpansionPanel
              title="Pekaboo"
              type="Presentations"
              icon={<ShoppingBagSvg />}
              typeColor={COLORS.lightgreen}
              target={goals?.product_presentation?.click_target}
              amount={`${goals?.product_presentation?.amount_target_clean}`}
            >
              {(closePanel: any) => {
                return (
                  <div className={classes.panel_content_container}>
                    <div className={classes.input_container}>
                      <Input
                        containerClassName={classes.input_main}
                        inputClassName={classes.input_field}
                        type="text"
                        placeholder="0"
                        value={`${pekabooAmount}`}
                        onChange={(e: any) => {
                          e.stopPropagation();
                          const val = e.target.value;
                          const isNumericVal = isNumericValue(val);
                          if (isNumericVal) {
                            setPekabooAmount(val);
                          }
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                      <Input
                        containerClassName={classes.input_main}
                        inputClassName={classes.input_field}
                        type="text"
                        placeholder="0"
                        value={pekabooTarget}
                        onChange={(e: any) => {
                          const val = e.target.value;
                          const isNumericVal = isNumericValue(val);
                          if (isNumericVal) {
                            setPekabooTarget(val);
                          }
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                    </div>
                    <div className={classes.btn_container}>
                      <Button
                        buttonClassName={classes.peekabo_amount_btn}
                        text="Register Sales Unit"
                        onClick={() => {
                          handleRegisterPekabooAmount();
                          closePanel();
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                      <Button
                        buttonClassName={classes.peekabo_presentation_btn}
                        text="Register Presentations"
                        onClick={() => {
                          handleRegisterPekabooTarget();
                          closePanel();
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                    </div>
                  </div>
                );
              }}
            </ExpansionPanel>
          )}
          {isEmployee && (
            <ExpansionPanel
              title="OTO"
              type="Number of OTO's"
              icon={<UsersSvg />}
              typeColor={COLORS.yellow}
              target={goals?.one_to_one_sale?.click_target}
              amount={`€${goals?.one_to_one_sale?.amount_target}`}
            >
              {(closePanel: any) => {
                return (
                  <div className={classes.panel_content_container}>
                    <div className={classes.input_container}>
                      <Input
                        containerClassName={classes.input_main}
                        inputClassName={classes.input_field}
                        type="text"
                        placeholder="0"
                        value={`€ ${oneToOneAmount}`}
                        onChange={(e: any) => {
                          e.stopPropagation();
                          const val = e.target.value.substring(1)?.trim();
                          const isNumericVal = isNumericValue(val);
                          if (isNumericVal) {
                            setOneToOneAmount(val);
                          }
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                      <Input
                        containerClassName={classes.input_main}
                        inputClassName={classes.input_field}
                        type="text"
                        value={oneToOneTarget}
                        placeholder="0"
                        onChange={(e: any) => {
                          const val = e.target.value;
                          const isNumericVal = isNumericValue(val);
                          if (isNumericVal) {
                            setOneToOneTarget(val);
                          }
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                    </div>
                    <div className={classes.btn_container}>
                      <Button
                        buttonClassName={classes.onetoone_amount_btn}
                        text="Register Amount"
                        onClick={() => {
                          handleRegisterOneToOneAmount();
                          closePanel();
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                      <Button
                        buttonClassName={classes.onetoone_meeting_btn}
                        text="Register OTO"
                        onClick={() => {
                          handleRegisterOneToOneTarget();
                          closePanel();
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                    </div>
                  </div>
                );
              }}
            </ExpansionPanel>
          )}
          {isGM && (
            <ExpansionPanel
              title="OTO"
              type="AVG. sales target"
              icon={<UserSvg />}
              typeColor={COLORS.yellow}
              amount={`€${averageOneToOneSales?.average_one_to_one_sales_target}`}
              hideSummary={hideSummary}
            >
              {(closePanel: any) => {
                return (
                  <div className={classes.panel_content_container}>
                    <div className={classes.input_container}>
                      <Input
                        containerClassName={classes.input_main}
                        inputClassName={classes.input_field_left_aligned}
                        placeholder="0"
                        type="text"
                        value={`€ ${avgOneToOneSalesAmount}`}
                        onChange={(e: any) => {
                          e.stopPropagation();
                          const val = e.target.value.substring(1)?.trim();
                          const isNumericVal = isNumericValue(val);
                          if (isNumericVal) {
                            setAvgOneToOneSalesAmount(val);
                          }
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                    </div>
                    <div className={classes.btn_container}>
                      <Button
                        buttonClassName={classes.onetoone_amount_btn_gm}
                        text="Register Amount"
                        onClick={() => {
                          handleAvgSalesAmountChange();
                          closePanel();
                        }}
                        disabled={activeMonth < stats?.current_month}
                      />
                    </div>
                  </div>
                );
              }}
            </ExpansionPanel>
          )}

          {isGM && (
            <div className={classes.message_input_container}>
              <div className={classes.panel_content_container}>
                <div className={classes.input_container}>
                  <textarea
                    className={classes.textarea}
                    placeholder="GM's message..."
                    value={gmMessage}
                    onChange={(e: any) => {
                      setGmMsgError(null);
                      setGmMessage(e.target.value);
                    }}
                  />
                </div>
                {gmMsgError && (
                  <div className={classes.gm_message_error}>{gmMsgError}</div>
                )}
                <div className={classes.btn_container}>
                  <Button
                    buttonClassName={classes.onetoone_amount_btn_gm}
                    text="Save"
                    onClick={() => {
                      if (gmMessage.length === 0) {
                        setGmMsgError('This is required.');
                        return;
                      }
                      const data = {
                        message: gmMessage,
                        countryId: user?.country_id,
                      };
                      saveGmMessage(data)
                        .then((res) => {
                          toast.success('Message Saved Successfully!');
                        })
                        .catch((err) => {
                          toast.error(err?.response?.data?.message);
                        });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* <Button
            disabled={
              presentationCount !==
                Number(goals?.product_presentation?.target) ||
              productSaleCount !== Number(goals?.product_sale?.target) ||
              oneToOneSaleCount !== Number(goals?.one_to_one_sale?.target) ||
              oneToOneSaleAmountCount !== Number(goals?.one_to_one_sale?.amount)
                ? false
                : true
            }
            onClick={handleSubmit}
            buttonClassName={classes.save_btn_class}
            text="Save"
          /> */}
      </div>

      <Modal closeButton={false} show={showOneToOneSaleAmountModal}>
        <OneToOneSalesAmountModal
          defaultValue={
            oneToOneSaleAmountCount
              ? oneToOneSaleAmountCount
              : goals?.one_to_one_sale?.amount
          }
          setOpen={setShowOneToOneSaleAmountModal}
          onSubmit={handleAmountSubmit}
        />
      </Modal>
      <LogoutModal
        isOpen={logoutModalOpen}
        setIsOpen={setLogoutModalOpen}
        handleLogout={logout}
      />
    </>
  );
};

export default Stats;
