import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSalesTargetSummary } from '../../services/general.services';

export const fetchSalesTargetSummaryAsync = createAsyncThunk<any, any, any>(
  'summary/fetchSalesTargetSummaryAsync',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await getSalesTargetSummary(params);
      return response.data.response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
