import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  getProductGoalsStatsService,
  getStoreSalesTarget,
  getUserGoalsApiCall,
} from '../../services/general.services';
import { getGoalsByMonthYearProduct } from '../../services/general.services';

export const getStoreSalesTargetAsync = createAsyncThunk<any, any, any>(
  'goals/getStoreSalesTargetAsync',
  async (params: string, { rejectWithValue }) => {
    try {
      const response = await getStoreSalesTarget(params); // Assuming fetchUser returns a Promise
      if (response) {
        return response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error); // Assuming the user data is in response.data
    }
  }
);

export const previousMonthStoreSalesTarget = createAsyncThunk<any, any, any>(
  'goals/previousMonthStoreSalesTarget',
  async (params: string, { rejectWithValue }) => {
    try {
      const response = await getStoreSalesTarget(params); // Assuming fetchUser returns a Promise
      if (response) {
        return response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error); // Assuming the user data is in response.data
    }
  }
);
