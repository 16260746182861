import React from 'react';

const ShoppingBagSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.32243 9.02914C4.47253 8.05348 5.31203 7.33325 6.29917 7.33325H15.7008C16.688 7.33325 17.5275 8.05347 17.6776 9.02914L18.8955 16.9458C19.0819 18.1573 18.1445 19.2499 16.9188 19.2499H5.08122C3.85545 19.2499 2.91809 18.1573 3.10448 16.9458L4.32243 9.02914Z"
        stroke="#C1C1CD"
        stroke-width="2"
      />
      <path
        d="M7.3335 7.33333V6.41667C7.3335 4.39162 8.97512 2.75 11.0002 2.75V2.75C13.0252 2.75 14.6668 4.39162 14.6668 6.41667V7.33333"
        stroke="#C1C1CD"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M14.6665 10.0834V11.0001C14.6665 13.0251 13.0249 14.6667 10.9998 14.6667V14.6667C8.97479 14.6667 7.33317 13.0251 7.33317 11.0001V10.0834"
        stroke="#C1C1CD"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default ShoppingBagSvg;
