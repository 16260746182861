import React from 'react';
import classes from './PresentBox.module.scss';
import { IHomeProps } from '../../models/IHome';
const PresentBox = ({
  src,
  icon,
  text,
  ratio,
  borderColor,
  containerClassName,
  imgClassName,
  textClassName,
  ratioClassName,
  value,
  isSelected,
  valueColor,
  ...props
}: IHomeProps) => {
  let cardClass = classes.container;
  let imgClass = classes.img;
  let textClass = classes.text;
  let ratioClass = classes.ratio;

  if (containerClassName) {
    cardClass = `${cardClass} ${containerClassName}`;
  }
  if (imgClassName) {
    imgClass = `${imgClass} ${imgClassName}`;
  }
  if (textClassName) {
    textClass = `${textClass} ${textClassName}`;
  }
  if (ratioClassName) {
    ratioClass = `${ratioClass} ${ratioClassName}`;
  }
  return (
    <>
      <div className={cardClass} {...props}>
        <div className={classes.icon}>{icon && icon}</div>
        {text && <div className={textClass}>{text}</div>}
        <div className={classes.target_heading}>Today's Target</div>
        {/* {ratio && <div className={ratioClass}>{ratio}</div>} */}
        {value && (
          <div className={classes.target_heading} style={{ color: valueColor }}>
            {value}
          </div>
        )}
        {borderColor && (
          <div
            style={{ background: borderColor }}
            className={classes.border_top}
          ></div>
        )}
      </div>
    </>
  );
};

export default PresentBox;
