import React from 'react';
import { Form } from 'react-bootstrap';
import classes from './Toggle.module.scss';

const Toggle = ({ label = ' ', value, onChange }: any) => {
  return (
    <div className={classes.container}>
      <Form>
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          className={classes.switch}
          label={label}
          onChange={onChange}
          checked={value}
        />
      </Form>
    </div>
  );
};

export default Toggle;
