import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNotifications } from '../../services/general.services';

export const fetchAllNotificationsAsync = createAsyncThunk<any, any, any>(
  'notifications/fetchAllNotificationsAsync',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await getNotifications(params);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
