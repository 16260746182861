import { createSlice } from '@reduxjs/toolkit';
import { getProductGoalsStatsAsync } from '../actions/productGoalsStats.action';
import {
  getGoalsByMonthAndYearAsync,
  getUserGoalsAsyncThunk,
  previousMonthGoalsAsyncApiCall,
} from '../actions/goalsByMonthAndYear.action';
import { IStatsReducers } from '../../models/reducers/IStatsReducers';
import { IGoalsReducer } from '../../models/reducers/IGoalsReducers';
import {
  getStoreSalesTargetAsync,
  previousMonthStoreSalesTarget,
} from '../actions/storeSalesTarget.action';
import {
  getCountrySalesTargetAsync,
  previousMonthCountrySalesTargetAsync,
} from '../actions/countrySalesTarget.action';

const initialState: IGoalsReducer = {
  goals: '',
  isLoading: false,
  error: '',
  previousMonthGoals: '',
  averageOneToOneSales: '',
  previousMonthAverageOneToOneSales: '',
  userGoals: '',
  isUserGoalsLoading: false,
};

export const goalsReducer = createSlice({
  name: 'goals',
  initialState,
  reducers: {
    restGoals: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGoalsByMonthAndYearAsync.pending, (state, action) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(getGoalsByMonthAndYearAsync.fulfilled, (state, action) => {
        state.goals = action.payload;
        state.isLoading = false;
        state.error = ''; // Clear previous errors on pending
      })
      .addCase(getGoalsByMonthAndYearAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action?.payload
          ? action?.payload
          : action?.payload?.message
          ? action?.payload?.message
          : 'An error occurred';
      })
      .addCase(previousMonthGoalsAsyncApiCall.fulfilled, (state, action) => {
        state.previousMonthGoals = action.payload;
      })
      .addCase(getUserGoalsAsyncThunk.fulfilled, (state, action) => {
        state.userGoals = action.payload;
        state.isUserGoalsLoading = false;
      })
      .addCase(getUserGoalsAsyncThunk.pending, (state, action) => {
        state.isUserGoalsLoading = true;
      })
      .addCase(getUserGoalsAsyncThunk.rejected, (state, action) => {
        state.isUserGoalsLoading = false;
      })
      .addCase(getStoreSalesTargetAsync.pending, (state, action) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(getStoreSalesTargetAsync.fulfilled, (state, action) => {
        state.goals = action.payload;
        state.isLoading = false;
        state.error = ''; // Clear previous errors on pending
      })
      .addCase(getStoreSalesTargetAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action?.payload
          ? action?.payload
          : action?.payload?.message
          ? action?.payload?.message
          : 'An error occurred';
      })
      .addCase(previousMonthStoreSalesTarget.fulfilled, (state, action) => {
        state.previousMonthGoals = action.payload;
      })
      .addCase(getCountrySalesTargetAsync.fulfilled, (state, action) => {
        state.averageOneToOneSales = action.payload;
      })
      .addCase(
        previousMonthCountrySalesTargetAsync.fulfilled,
        (state, action) => {
          state.previousMonthAverageOneToOneSales = action.payload;
        }
      );
  },
});

export const { restGoals } = goalsReducer.actions;
export default goalsReducer.reducer;
