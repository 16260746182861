import React, { useEffect, useRef, useState } from 'react';
import classes from './StatsStore.module.scss';
import StuffCard from '../../components/common/Card/StuffCard';
import { CircleProgressBar } from '../../components/circleProgressBar/CircleProgressBar';
import ShoppingBagImg from '../../assets/png/shoppingBag.png';
import UserImg from '../../assets/png/users.png';
import { Carousel, CarouselItem, Dropdown } from 'react-bootstrap';
import arrowLeft from '../../assets/png/arrowLeft.png';
import arrowRight from '../../assets/png/arrowRight.png';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS, MONTHS } from '../../utils/constants';
import { getUserGoalsAsyncThunk } from '../../redux/actions/goalsByMonthAndYear.action';
import Loader from '../../components/common/Loader/Loader';
import { ROLES } from '../../utils/constants';
import LogoutSvg from '../../assets/svg/LogoutSvg';
import useLogout from '../../utils/hooks/useLogout';
import LogoutModal from '../../components/common/LogoutModal/LogoutModal';
import Select from '../../components/common/ReactSelect/ReactSelect';
import {
  getCities,
  getPdfReport,
  getStores,
  getUsers,
} from '../../services/general.services';
import Toggle from '../../components/common/Toggle/Toggle';
import {
  capitalizeFirstLetter,
  copyToClipboard,
  downloadFileFromURL,
  getFontSize,
} from '../../utils/helpers';
import PdfSvg from '../../assets/svg/PdfSvg';
import PdfReportModal from '../../components/PdfReportModal/PdfReportModal';
import { toast } from 'react-toastify';

const Years = [
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030',
];

export const StatsStore = () => {
  const dispatch = useDispatch<any>();
  const { stats } = useSelector((state: any) => state?.root?.stats);
  const { user } = useSelector((state: any) => state?.root?.user);
  const isManager = user?.role === ROLES.Manager;
  const isEmployee = user?.role === ROLES.Employee;
  const isGM = user?.role === ROLES.GM;
  const [activeMonth, setActiveMonth] = useState(stats?.current_month - 1);
  const isCurrentMonthActive = activeMonth === stats?.current_month - 1;
  console.log(
    'activeMonth->isCurrentMonthActive',
    activeMonth,
    isCurrentMonthActive
  );
  const [activeYear, setActiveYear] = useState(3);
  const [today, setToday] = useState<any>(true);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [isPdfReportModalOpen, setIsPdfReportModalOpen] = useState(false);
  const [pdfReportUrl, setPdfReportUrl] = useState('');
  const [selectedUser, setSelectedUser] = useState(
    isManager ? user?.related_stores[0]?.id : null
  );
  console.log('today>>>', today);

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [users, setUsers] = useState<any>([]);
  console.log('users->>>', users);
  const [cities, setCities] = useState<any>([]);
  const [stores, setStores] = useState<any>([]);
  console.log('users->>cities-->>stores', users, cities, stores);

  const { logout } = useLogout();
  const { userGoals, isUserGoalsLoading } = useSelector(
    (state: any) => state?.root?.goals
  );

  useEffect(() => {
    let data = {
      user_id: selectedUser ?? null,
      month: activeMonth + 1,
      year: Years[activeYear],
      //if not manager or gm
      today: today,
      store_id: selectedStore ?? null,
      city_id: selectedCity ?? null,
    };
    const allIsSelectedInUsers = selectedUser === user?.related_stores[0]?.id;
    const managerData = {
      user_id: allIsSelectedInUsers ? null : selectedUser,
      month: activeMonth + 1,
      year: Years[activeYear],
      store_id: allIsSelectedInUsers ? selectedUser : null,
      today: today,
    };
    dispatch(getUserGoalsAsyncThunk(isManager ? managerData : data));
  }, [today]);

  //fetch cities list
  useEffect(() => {
    if (isGM) {
      getCities()
        .then((res) => {
          setCities(res?.data?.cities);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  //fetch stores list
  useEffect(() => {
    if (isGM) {
      let data = {
        city_id: selectedCity ?? '',
      };
      setSelectedStore(null);
      getStores(data)
        .then((res) => {
          setStores(res?.data?.stores);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedCity]);

  //fetch users list
  useEffect(() => {
    let data = {
      store_id: selectedStore ?? '',
      city_id: selectedCity ?? '',
    };
    setSelectedUser(isManager ? user?.related_stores[0]?.id : null);
    getUsers(data)
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedCity, selectedStore]);

  const handleSelect = (month: number) => {
    const isCurrentMonth = month === stats?.current_month;
    const gmData = { store_id: selectedStore, city_id: selectedCity };
    const data = {
      today: isCurrentMonth ? today : false,
      user_id: selectedUser,
      month: month,
      year: Years[activeYear],
      ...(isGM && gmData),
    };
    dispatch(getUserGoalsAsyncThunk(data));
  };

  const handleUserChange = (selectedOption: any) => {
    const gmData = { store_id: selectedStore, city_id: selectedCity };
    const managerData = {
      store_id: selectedOption?.value,
      user_id: null,
    };
    const allIsSelected = selectedOption.label === 'All';
    setSelectedUser(selectedOption.value);
    const data = {
      today: isCurrentMonthActive ? today : false,
      user_id: selectedOption.value,
      month: activeMonth + 1,
      year: Years[activeYear],
      ...(isGM && gmData),
      ...(isManager && allIsSelected && managerData),
    };
    dispatch(getUserGoalsAsyncThunk(data));
  };

  const handleCityChange = (selectedOption: any) => {
    const gmData = { store_id: null, city_id: selectedOption.value };
    setSelectedCity(selectedOption.value);
    setSelectedStore(null);
    setSelectedUser(null);
    const data = {
      today: isCurrentMonthActive ? today : false,
      user_id: null,
      month: activeMonth + 1,
      year: Years[activeYear],
      ...(isGM && gmData),
    };
    dispatch(getUserGoalsAsyncThunk(data));
  };

  const handleStoreChange = (selectedOption: any) => {
    const gmData = { store_id: selectedOption.value, city_id: selectedCity };
    setSelectedStore(selectedOption.value);
    const data = {
      today: isCurrentMonthActive ? today : false,
      user_id: null,
      month: activeMonth + 1,
      year: Years[activeYear],
      ...(isGM && gmData),
    };
    dispatch(getUserGoalsAsyncThunk(data));
  };

  const handleYearChange = (year: any) => {
    const gmData = { store_id: selectedStore, city_id: selectedCity };
    const data = {
      today: isCurrentMonthActive ? today : false,
      user_id: selectedUser,
      month: activeMonth + 1,
      year: year,
      ...(isGM && gmData),
    };
    dispatch(getUserGoalsAsyncThunk(data));
  };

  const hanldeLogout = () => {
    setLogoutModalOpen(true);
  };

  const handleToggleChange = (e: any) => {
    setToday(e.target.checked);
  };

  const getManagerData = () => {
    let name = '';
    if (selectedUser === user?.related_stores[0]?.id) {
      name = user?.related_stores[0]?.name;
    } else {
      name = users?.find((user: any) => user.id === selectedUser)?.name;
    }
    return name;
  };

  const getGmData = () => {
    let name = '';
    if (selectedUser || selectedCity || selectedStore) {
      if (selectedUser) {
        name = users?.find((user: any) => user.id === selectedUser)?.name;
      } else if (selectedCity) {
        name = cities?.find((city: any) => city.id === selectedCity)?.name;
      } else {
        name = stores?.find((store: any) => store.id === selectedStore)?.name;
      }
    } else {
      name = user?.country_name;
    }
    return name;
  };

  const handlePdfBtnClick = () => {
    const data = {
      month: activeMonth + 1,
      year: Years[activeYear],
      city_id: selectedCity ?? '',
      store_id: selectedStore ?? '',
      //user_id: selectedUser ?? '',
    };

    getPdfReport(data)
      .then((res: any) => {
        if (res?.data?.url) {
          setPdfReportUrl(res.data.url);
          setIsPdfReportModalOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isUserGoalsLoading ? (
        <Loader />
      ) : (
        <div className={classes.container}>
          <button className={classes.logout_btn} onClick={hanldeLogout}>
            Logout
            <LogoutSvg />
          </button>
          {(isManager || isGM) && (
            <div className={classes.pdf_btn} onClick={handlePdfBtnClick}>
              <PdfSvg />
            </div>
          )}
          {isEmployee && isCurrentMonthActive && (
            <div className={classes.toggle_btn_employee}>
              <Toggle value={today} onChange={handleToggleChange} />
              <div className={classes.label}>{today ? 'Today' : 'Month'}</div>
            </div>
          )}
          {isManager && user && (
            <>
              {isCurrentMonthActive && (
                <div className={classes.toggle_btn_manager}>
                  <Toggle value={today} onChange={handleToggleChange} />
                  <div className={classes.label}>
                    {today ? 'Today' : 'Month'}
                  </div>
                </div>
              )}
              <div className={classes.selector}>
                <Select
                  options={[
                    {
                      id: user?.related_stores[0]?.id,
                      name: 'All',
                    },
                    ...users,
                  ]}
                  onChange={handleUserChange}
                  value={selectedUser}
                  mainClassName={classes.select_main}
                  menuStyles={{
                    left: '-45px',
                    width: '200px',
                  }}
                />
              </div>
            </>
          )}
          {isGM && (
            <>
              {isCurrentMonthActive && (
                <div className={classes.toggle_btn_gm}>
                  <Toggle value={today} onChange={handleToggleChange} />
                  <div className={classes.label}>
                    {today ? 'Today' : 'Month'}
                  </div>
                </div>
              )}
              <div className={classes.buttons}>
                <Select
                  defaultValue="Select City"
                  options={[{ id: null, name: 'All Cities' }, ...cities]}
                  onChange={handleCityChange}
                  value={selectedCity}
                  mainClassName={classes.select_main}
                  menuStyles={{
                    left: '-15px',
                    width: '130px',
                  }}
                />
                <Select
                  defaultValue="Select Store"
                  options={[{ id: null, name: 'All Stores' }, ...stores]}
                  onChange={handleStoreChange}
                  value={selectedStore}
                  mainClassName={classes.select_main}
                  menuStyles={{
                    left: '-40px',
                    width: '170px',
                  }}
                />
                <Select
                  options={[{ id: null, name: 'All Staff' }, ...users]}
                  onChange={handleUserChange}
                  value={selectedUser}
                  mainClassName={classes.select_main}
                  menuStyles={{
                    left: '-85px',
                    width: '200px',
                  }}
                />
              </div>
            </>
          )}
          <div className={classes.circle_progress_bar_container}>
            <div className={classes.carousel_container}>
              <Carousel
                prevIcon={<img src={arrowLeft} width="24px" height="24px" />}
                nextIcon={<img src={arrowRight} width="24px" height="24px" />}
                onSelect={(data) => {
                  setActiveMonth(data);
                  handleSelect(data + 1);
                }}
                activeIndex={activeMonth}
                slide={false}
                indicators={false}
                interval={null}
              >
                {MONTHS?.map((month) => {
                  return (
                    <CarouselItem>
                      <div className={classes.wrapper}>
                        <CircleProgressBar
                          // date={`${month} ${Years[activeYear]}`}
                          date={`${month}`}
                          role={user?.role}
                          ratio={`${userGoals?.total_achieved}/${userGoals?.total_target}`}
                          percentage={
                            userGoals?.goal_details_by_type?.total_sale
                              ?.total_sale_relative_percentage
                          }
                          progress={userGoals?.total_percentage_achieved}
                          progressBarcolor={COLORS.carrot}
                          ratingClassName={
                            userGoals?.total_percentage_achieved > 99
                              ? classes.green_rating
                              : userGoals?.total_percentage_achieved > 50
                              ? classes.main_rating
                              : classes.red_rating
                          }
                          btnText={
                            user?.role === 'Employee' ? `${user?.name}` : ''
                          }
                          dropdownToogleText={
                            user?.role === 'Manager' ? 'Sarah Adams' : ''
                          }
                          dropdownItems={
                            isManager &&
                            user && [
                              {
                                id: user?.user_id,
                                name: 'Me ',
                              },
                              ...user?.underlying_user_ids,
                            ]
                          }
                          titleText={
                            user?.role === 'GM'
                              ? ''
                              : `${user?.city_name}, ${user?.store_name}`
                          }
                          carousalItems={Years}
                          onChange={handleUserChange}
                          selectedItem={selectedUser}
                          carousalActiveIndex={activeYear}
                          onCarousalSelect={(event) => {
                            setActiveYear(event);
                            handleYearChange(Years[event]);
                          }}
                          totalAmountTarget={`€${userGoals?.goal_details_by_type?.total_sale?.total_amount_target}`}
                          totalAmountAchieved={`€${userGoals?.goal_details_by_type?.total_sale?.total_amount_achieved}`}
                          amountStyles={{
                            fontSize: getFontSize(
                              userGoals?.goal_details_by_type?.total_sale
                                ?.total_amount_achieved,
                              27,
                              false
                            ),
                          }}
                        />
                      </div>
                    </CarouselItem>
                  );
                })}
              </Carousel>
            </div>
          </div>
          <div className={classes.user_details}>
            {isEmployee
              ? capitalizeFirstLetter(user?.name)
              : isManager
              ? getManagerData()
              : getGmData()}
          </div>
          <div className={classes.sub_container}>
            {/* <StuffCard
              color="#FFA699"
              src={ShoppingBagImg}
              completed={
                userGoals?.goal_details_by_type?.product_sale
                  ?.percentage_achieved
              }
              ratio={`${userGoals?.goal_details_by_type?.product_sale?.total_achieved}/${userGoals?.goal_details_by_type?.product_sale?.total_target}`}
              percentage={`${userGoals?.goal_details_by_type?.product_sale?.percentage_achieved}%`}
              title={`${user?.active_product_name} Sales`}
            /> */}
            <StuffCard
              src={UserImg}
              color="#AD7BFF"
              completed={
                userGoals?.goal_details_by_type?.one_to_one_sale
                  ?.percentage_achieved
              }
              ratio={`${userGoals?.goal_details_by_type?.one_to_one_sale?.total_achieved}/${userGoals?.goal_details_by_type?.one_to_one_sale?.total_target}`}
              percentage={`${userGoals?.goal_details_by_type?.one_to_one_sale?.percentage_achieved}%`}
              amount={`${userGoals?.goal_details_by_type?.one_to_one_sale?.amount_achieved}/${userGoals?.goal_details_by_type?.one_to_one_sale?.amount_target}`}
              title={`OTO`}
              heading="OTO"
              containerClassName={classes.onetoone_card}
              totalRatio={`${userGoals?.goal_details_by_type?.one_to_one_sale?.total_clicks_achieved}/${userGoals?.goal_details_by_type?.one_to_one_sale?.total_click_target}`}
              totalPercentage={`${userGoals?.goal_details_by_type?.one_to_one_sale?.total_clicks_percentage_achieved}%`}
              totalSalesRatio={`€${userGoals?.goal_details_by_type?.one_to_one_sale?.total_amount_achieved_clean}/€${userGoals?.goal_details_by_type?.one_to_one_sale?.total_amount_target_clean}`}
              totalSalesPercentage={`${userGoals?.goal_details_by_type?.one_to_one_sale?.total_amount_percentage_achieved}%`}
              avgSale={`€${userGoals?.goal_details_by_type?.one_to_one_sale?.average_sale_per_click}`}
              avgSalePercentage={`${userGoals?.goal_details_by_type?.one_to_one_sale?.average_one_to_one_sale_percentage}%`}
            />
            <StuffCard
              color="#00FAD9"
              src={ShoppingBagImg}
              completed={
                userGoals?.goal_details_by_type?.product_presentation
                  ?.percentage_achieved
              }
              title={`Presentation`}
              ratio={`${userGoals?.goal_details_by_type?.product_presentation?.total_achieved}/${userGoals?.goal_details_by_type?.product_presentation?.total_target}`}
              percentage={`${userGoals?.goal_details_by_type?.product_presentation?.percentage_achieved}%`}
              containerClassName={classes.beekaboo_card}
              heading="PEKABOO"
              totalRatio={`${userGoals?.goal_details_by_type?.product_presentation?.total_clicks_achieved}/${userGoals?.goal_details_by_type?.product_presentation?.total_click_target}`}
              totalPercentage={`${userGoals?.goal_details_by_type?.product_presentation?.total_clicks_percentage_achieved}%`}
              totalSalesRatio={`${userGoals?.goal_details_by_type?.product_presentation?.total_amount_achieved_clean}/${userGoals?.goal_details_by_type?.product_presentation?.total_amount_target_clean}`}
              totalSalesPercentage={`${userGoals?.goal_details_by_type?.product_presentation?.total_amount_percentage_achieved}%`}
              avgSale={`${userGoals?.goal_details_by_type?.product_presentation?.SalesPerTenPresentationsRatio}`}
              avgSalePercentage={`${userGoals?.goal_details_by_type?.product_presentation?.SalesPresentationsPercentage}`}
            />
          </div>
          <PdfReportModal
            isOpen={isPdfReportModalOpen}
            setIsOpen={setIsPdfReportModalOpen}
            reportUrl={pdfReportUrl}
            handleCopy={() => {
              copyToClipboard(pdfReportUrl);
              toast.success('Copied to clipboard!');
              setPdfReportUrl('');
              setIsPdfReportModalOpen(false);
            }}
            handleOpenInTab={() => {
              const newTab = window.open(pdfReportUrl, '_blank');
              if (newTab) {
                newTab.focus();
              }
              setPdfReportUrl('');
              setIsPdfReportModalOpen(false);
            }}
          />
          <LogoutModal
            isOpen={logoutModalOpen}
            setIsOpen={setLogoutModalOpen}
            handleLogout={logout}
          />
        </div>
      )}
    </>
  );
};
