import { createSlice } from "@reduxjs/toolkit";
import { getProductGoalsStatsAsync } from "../actions/productGoalsStats.action";
import { IStatsReducers } from "../../models/reducers/IStatsReducers";

const initialState: IStatsReducers = {
  stats: "",
  isLoading: false,
  error: "",
};

export const userReducer = createSlice({
  name: "stats",
  initialState,
  reducers: {
    restProductGoalStats: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductGoalsStatsAsync.pending, (state, action) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getProductGoalsStatsAsync.fulfilled, (state, action) => {
        state.stats = action.payload;
        state.isLoading = false;
        state.error = ""; // Clear previous errors on pending
      })
      .addCase(getProductGoalsStatsAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action?.payload
          ? action?.payload
          : action?.payload?.message
          ? action?.payload?.message
          : "An error occurred";
      });
  },
});

export const { restProductGoalStats } = userReducer.actions;
export default userReducer.reducer;
