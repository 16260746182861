export const passwordRegex = {
  poorRegex: /^.{9,}$/,
  mediumRegex: /^(?=.*[A-Z])(?=.*\d).+$/,
  strongRegex: /^(?=.*\d)(?=.*[^A-Za-z\d\s])[A-Za-z\d@$!%*?&]+$/,
  veryStrongRegex:
    /^(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*[@#$!%*?&].*[@#$!%*?&]).*$/,
};

export const COLORS = {
  carrot: '#ff7966',
  lightgreen: '#00fad9',
  yellow: '#f9b949',
  gray: '#c1c1cd',
  white: '#ffffff',
};

export const ROLES = {
  Employee: 'Employee',
  Manager: 'Manager',
  GM: 'GM',
};

export const StateInfoTypes = {
  Presentaion: 'Presentaion',
  OneToOneSales: 'OneToOneSales',
  TotalSales: 'TotalSales',
  Sales: 'Sales',
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DummyMngrSummary = [
  {
    staff_id: 22,
    staff_name: 'Johanne Teixeira De Abreu',
    total_target: 0,
    total_target_formatted: '0',
  },
  {
    staff_id: 23,
    staff_name: 'Maria Blanco Belver',
    total_target: 0,
    total_target_formatted: '0',
  },
  {
    staff_id: 24,
    staff_name: 'Eva Marques Scasso',
    total_target: 0,
    total_target_formatted: '0',
  },
  {
    staff_id: 25,
    staff_name: 'Cristiano Sartore',
    total_target: 0,
    total_target_formatted: '0',
  },
  {
    staff_id: 26,
    staff_name: 'Desislava Nikolovai Vanova',
    total_target: 0,
    total_target_formatted: '0',
  },
  {
    staff_id: 27,
    staff_name: 'Karolina Pecionaityte',
    total_target: 0,
    total_target_formatted: '0',
  },
  {
    staff_id: 28,
    staff_name: 'Vanesa Santander',
    total_target: 0,
    total_target_formatted: '0',
  },
  {
    store_total_target: '11.00',
    store_total_target_formatted: '11',
  },
  {
    staff_total_target: 0,
    staff_total_target_formatted: '0',
  },
  {
    staff_total_target_vs_store_total_target: -11,
    staff_total_target_vs_store_total_target_formatted: '-11',
  },
];
