import React from 'react';
import { ROLES } from '../constants';
import { useSelector } from 'react-redux';

const useUser = () => {
  const user = useSelector((state: any) => state?.root?.user?.user);
  const isEmployee = user?.role === ROLES.Employee;
  const isManager = user?.role === ROLES.Manager;
  const isGM = user?.role === ROLES.GM;

  return {
    user,
    isEmployee,
    isManager,
    isGM,
  };
};

export default useUser;
