export const DottedCircleBar = ({
  radius,
  strokeWidth,
  color,
  dotLength = 0.5,
  innerDistance = 10,
}: any) => {
  return (
    <div>
      <svg height={130} width={200}>
        <circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="transparent"
          stroke="#282832"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="transparent"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={`${dotLength},${innerDistance}`}
          strokeDashoffset="6"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};
