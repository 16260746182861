import React from 'react';
import classes from './TargetBox.module.scss';

const TargetBox = (props: any) => {
  const {
    icon,
    amount,
    target,
    color,
    targetColor,
    amountColor,
    topBorderClassName,
    targetStyles,
    amountStyles,
    heading,
  } = props;

  let topBorderClass = classes.top_border;
  if (topBorderClassName) {
    topBorderClass = `${topBorderClass} ${topBorderClassName}`;
  }
  return (
    <div className={classes.container}>
      <div className={classes.top_content}>
        {icon && icon}
        {heading && <div className={classes.heading}>{heading}</div>}
      </div>

      <div className={classes.figures}>
        {target && (
          <div className={classes.target} style={{ ...targetStyles }}>
            {target}
          </div>
        )}
        {amount && (
          <div className={classes.amount} style={{ ...amountStyles }}>
            {amount}
          </div>
        )}
      </div>
      <div className={topBorderClass}></div>
    </div>
  );
};

export default TargetBox;
