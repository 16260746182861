import React, { useEffect, useState } from 'react';
import classes from './Notifications.module.scss';
import { INotification } from '../../models/INotifications';
import NotificationCard from '../../components/NotificationCard/NotificationCard';
import { Carousel, CarouselItem, Dropdown } from 'react-bootstrap';
import arrowLeft from '../../assets/png/arrowLeft.png';
import arrowRight from '../../assets/png/arrowRight.png';
import LogoutSvg from '../../assets/svg/LogoutSvg';
import LogoutModal from '../../components/common/LogoutModal/LogoutModal';
import useLogout from '../../utils/hooks/useLogout';
import DateCarousal from '../../components/DateCarousal/DateCarousal';
import moment from 'moment';
import Loader from '../../components/common/Loader/Loader';
import NoNotificationsSvg from '../../assets/svg/NoNotifications';
import { COLORS } from '../../utils/constants';
import useUser from '../../utils/hooks/useUser';
import Select from '../../components/common/ReactSelect/ReactSelect';
import { getUsers } from '../../services/general.services';

const DATA = [
  'Tuesday, April 2',
  'Wednesday, April 3',
  'Thursday, April 4',
  'Friday, April 5',
];

const DATE_FORMAT = 'dddd, MMMM D';

const Notifications = ({
  notifications,
  isLoading,
  date,
  setDate,
  setSelectedUserId,
}: {
  notifications: INotification[];
  isLoading: boolean;
  date: string;
  setDate: (date: any) => void;
  setSelectedUserId: (userId: any) => void;
}) => {
  const { logout } = useLogout();
  const { isManager, user } = useUser();
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState<any>([]);
  const [logoutModalOpen, setLogoutModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isManager) {
      setSelectedUserId(selectedUser);
    }
  }, [selectedUser]);
  useEffect(() => {
    const data = {
      store_id: user?.related_stores[0]?.id,
    };
    getUsers(data)
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getClassName = (type: string) => {
    let cls = '';
    switch (type) {
      case 'product_presentation':
        cls = classes.peekaboo_card;
        break;
      case 'one_to_one_sale':
        cls = classes.onetoone_card;
        break;
      case 'total_sale':
        cls = classes.sale_card;
        break;
      case 'avr_one_to_one_sale':
        cls = classes.sale_card;
        break;
      case 'active_in_app':
        cls = classes.activeapp_card;
        break;
      default:
        cls = classes.activeapp_card;
    }
    return cls;
  };

  const handleLogout = () => {
    setLogoutModalOpen(true);
  };

  const handleNextClick = () => {
    setDate(moment(date).add(1, 'days'));
  };
  const handleBackClick = () => {
    setDate(moment(date).subtract(1, 'days'));
  };

  const handleUserChange = (selectedOption: any) => {
    const allIsSelected = selectedOption.label === 'All';
    setSelectedUser(selectedOption.value);
    const data = {
      user_id: selectedOption.value,
    };
    // dispatch(getUserGoalsAsyncThunk(data));
  };

  return (
    <div className={classes.container}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <button className={classes.logout_btn} onClick={handleLogout}>
            Logout
            <LogoutSvg />
          </button>
          <div className={classes.main_heading}>Notifications</div>
          <div className={classes.date_carousal_container}>
            <DateCarousal
              date={moment(date).format(DATE_FORMAT)}
              onClickBack={handleBackClick}
              onClickNext={handleNextClick}
              dateClassName={classes.date_class}
            />
          </div>
          {isManager && (
            <div className={classes.staff_selector_container}>
              <Select
                options={[{ id: null, name: 'All Staff' }, ...users]}
                onChange={handleUserChange}
                value={selectedUser}
                mainClassName={classes.select_main}
                menuStyles={{
                  left: '-60px',
                  width: '200px',
                  zIndex: '1',
                }}
              />
            </div>
          )}
          <div className={classes.notifications_container}>
            {notifications?.length === 0 && (
              <div className={classes.no_notification_content}>
                <NoNotificationsSvg color={COLORS.white} />
                <div>No Notifications Found!</div>
              </div>
            )}
            {notifications?.map((notification: any) => {
              return (
                <NotificationCard
                  notification={notification}
                  circleClassName={getClassName(notification?.goal_type)}
                />
              );
            })}
          </div>
          {logoutModalOpen && (
            <LogoutModal
              isOpen={logoutModalOpen}
              setIsOpen={setLogoutModalOpen}
              handleLogout={logout}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Notifications;
