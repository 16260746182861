import React from 'react';

const ArrownDownSvgIcon = ({ className }: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 9L12.7071 16.2929C12.3166 16.6834 11.6834 16.6834 11.2929 16.2929L4 9"
        stroke="#E0E0E6"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default ArrownDownSvgIcon;
