import React, { useEffect, useState } from 'react';
import classes from './ExpansionPanel.module.scss';
import ArrownDownSvg from '../../assets/svg/ArrowDown';
import useUser from '../../utils/hooks/useUser';
import ArrownDownSvgIcon from '../../assets/svg/ArrowDownSvg';
import ArrownUpSvgIcon from '../../assets/svg/ArrowUpSvg';
import { useSelector } from 'react-redux';

const ExpansionPanel = ({
  summaryContainerClassName,
  detailContainerClassName,
  mainContainerClassName,
  children,
  title,
  type,
  src,
  icon,
  target,
  amount,
  typeColor,
  hideSummary,
  setShowSummary,
  setSummaryData,
}: any) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const summary = useSelector((state: any) => state?.root?.summary?.summary);

  const { isGM } = useUser();

  const closePanel = () => {
    setIsCollapsed(true);
  };
  let summaryContainerClass = classes.summary_container;
  let detailContainerClass = classes.detail_container;
  let mainClass = classes.main;
  if (mainContainerClassName) {
    mainClass = `${mainClass} ${mainContainerClassName}`;
  }
  if (summaryContainerClassName) {
    summaryContainerClass = `${summaryContainerClass} ${summaryContainerClassName}`;
  }
  if (detailContainerClassName) {
    detailContainerClass = `${detailContainerClass} ${detailContainerClassName}`;
  }
  return (
    <div
      className={mainClass}
      onClick={(e: any) => {
        console.log('e.target.className', e.target.className);
        const isSummaryClicked =
          e.target.className.includes('ExpansionPanel_summary') ||
          e.target.className.includes('ExpansionPanel_title') ||
          e.target.className.includes('ExpansionPanel_amount_value');
        if (isSummaryClicked) {
          setIsCollapsed(!isCollapsed);
          if (setSummaryData && setShowSummary) {
            if (isCollapsed) {
              setSummaryData(summary);
              setShowSummary(true);
            } else {
              setSummaryData([]);
              setShowSummary(false);
            }
          }

          // if (isCollapsed) {
          //   hideSummary();
          // }
        }
      }}
    >
      <div className={summaryContainerClass}>
        <div className={classes.section_left}>
          {icon && (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
            >
              {icon}
            </div>
          )}
          <div className={classes.title_container}>
            <div className={classes.title}>{title}</div>
            <div className={classes.title} style={{ color: typeColor }}>
              {type}
            </div>
            {!isGM && (
              <div className={classes.title} style={{ color: typeColor }}>
               {type === 'Presentations' ? 'Sales Target in Units' : 'Sales Target'}
              </div>
            )}
          </div>
        </div>
        <div className={classes.section_right}>
          <div className={classes.amount_container}>
            <div className={classes.empty_div}></div>
            {!isGM && <div className={classes.amount_value}>{target}</div>}
            <div className={classes.amount_value}>{amount}</div>
          </div>
          <div
            className={classes.icon_container}
            onClick={() => {
              setIsCollapsed(!isCollapsed);
              if (setSummaryData && setShowSummary) {
                if (isCollapsed) {
                  setSummaryData(summary);
                  setShowSummary(true);
                } else {
                  setSummaryData([]);
                  setShowSummary(false);
                }
              }
            }}
          >
            {isCollapsed ? <ArrownDownSvgIcon /> : <ArrownUpSvgIcon />}
          </div>
        </div>
      </div>
      {!isCollapsed && children && (
        <div className={detailContainerClass}>{children(closePanel)}</div>
      )}
    </div>
  );
};

export default ExpansionPanel;
