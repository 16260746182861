import React from 'react';

const NoNotificationsSvg = ({ color }: { color?: string }) => {
  return (
    <div>
      <svg
        width="125px"
        height="200px"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color}>
          <path d="m 8 0 c -0.828125 0 -1.5 0.671875 -1.5 1.5 c 0 0.078125 0.007812 0.15625 0.019531 0.234375 c -1.378906 0.425781 -2.503906 1.425781 -3.09375 2.742187 l 8.527344 8.523438 h 1.546875 c 0.554688 0 1 -0.445312 1 -1 s -0.445312 -1 -1 -1 h -0.5 v -4.5 c 0 -2.191406 -1.425781 -4.125 -3.519531 -4.773438 c 0.011719 -0.074218 0.019531 -0.152343 0.019531 -0.226562 c 0 -0.828125 -0.671875 -1.5 -1.5 -1.5 z m -4.988281 6.183594 c -0.007813 0.105468 -0.011719 0.210937 -0.011719 0.316406 v 4.5 h -0.5 c -0.554688 0 -1 0.445312 -1 1 s 0.445312 1 1 1 h 7.328125 z m 2.988281 7.816406 c 0 0.714844 0.382812 1.375 1 1.734375 c 0.617188 0.355469 1.382812 0.355469 2 0 c 0.617188 -0.359375 1 -1.019531 1 -1.734375 z m 0 0" />
          <path d="m 1.53125 0.457031 l -1.0625 1.0625 l 14 14 l 1.0625 -1.0625 z m 0 0" />
        </g>
      </svg>
    </div>
  );
};

export default NoNotificationsSvg;
