import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from './reducers/userReducer';
import productGoalsStatsReducer from './reducers/productGoalsStatsReducer';
import goalsByMonthAndYearReducer from './reducers/goalsByMonthAndYearReducer';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import notificationsReducer from './reducers/notificationsReducer';
import summaryReducer from './reducers/summaryReducer';

const persistConfig = {
  key: 'root',
  storage: storage,
};

const reducers = combineReducers({
  user: userReducer,
  stats: productGoalsStatsReducer,
  goals: goalsByMonthAndYearReducer,
  notifications: notificationsReducer,
  summary: summaryReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: {
    root: persistedReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
