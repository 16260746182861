import React from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import classes from './LogoutModal.module.scss';

const LogoutModal = ({
  isOpen = false,
  handleLogout,
  setIsOpen = () => {},
}: {
  isOpen: boolean;
  handleLogout?: () => void;
  setIsOpen?: (value: any) => void;
}) => {
  return (
    <>
      <Modal closeButton={false} show={isOpen}>
        <div className={classes.text}>Are you sure you want to logout?</div>
        <div className={classes.btns_container}>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            containerClassName={classes.cancel_btn}
            text="No"
          />
          <Button
            onClick={() => {
              if (handleLogout) handleLogout();
            }}
            containerClassName={classes.submit_btn}
            text="Yes"
          />
        </div>
      </Modal>
    </>
  );
};

export default LogoutModal;
