import React from 'react';
import { useServiceWorker } from './ServiceWorkerContext'; // Correct import statement

const UpdateNotification = () => {
  const { newVersionAvailable, hideUpdate } = useServiceWorker();

  if (!newVersionAvailable) return null;

  return (
    <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
      <p>New version available!</p>
      <button onClick={() => { window.location.reload(); hideUpdate(); }}>Update Now</button>
    </div>
  );
};

export default UpdateNotification;
