import React from 'react';

const UndoSvg = () => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6H1V1M1.29102 14.3569C2.22284 15.7918 3.59014 16.8902 5.19218 17.4907C6.79422 18.0913 8.547 18.1624 10.1925 17.6937C11.8379 17.225 13.2893 16.2413 14.3344 14.8867C15.3795 13.5321 15.963 11.878 15.9989 10.1675C16.0347 8.45695 15.5211 6.78001 14.5337 5.38281C13.5462 3.98561 12.1366 2.942 10.5122 2.40479C8.88783 1.86757 7.13408 1.86499 5.5083 2.39795C3.88252 2.93091 2.47059 3.97095 1.47949 5.36556"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UndoSvg;
