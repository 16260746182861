import React from 'react';

const ArrownUpSvgIcon = ({ className }: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 15L11.2929 7.70711C11.6834 7.31658 12.3166 7.31658 12.7071 7.70711L20 15"
        stroke="#E0E0E6"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default ArrownUpSvgIcon;
