import React from 'react';
import { useDispatch } from 'react-redux';
import { restGoals } from '../../redux/reducers/goalsByMonthAndYearReducer';
import { resetUser } from '../../redux/reducers/userReducer';
import { restProductGoalStats } from '../../redux/reducers/productGoalsStatsReducer';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch(resetUser());
    dispatch(restGoals());
    dispatch(restProductGoalStats());
    navigate('/login');
  };

  return { logout };
};

export default useLogout;
