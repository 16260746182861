import React from 'react';
import classes from './Button.module.scss';
import { IButtonProps } from '../../../models/common/IButtonProps';
import { Button as BootStrapButton } from 'react-bootstrap';
const Button = ({
  buttonClassName,
  containerClassName,
  text,
  disabled,
  onClick,
  ...props
}: IButtonProps) => {
  let buttonClass = disabled
    ? `${classes.disabled} ${classes.btn}`
    : classes.btn;
  let containerClass = classes.container;
  if (buttonClassName) {
    buttonClass = `${buttonClass} ${buttonClassName}`;
  }
  if (containerClassName) {
    containerClass = `${containerClass} ${containerClassName}`;
  }
  return (
    <div className={containerClass}>
      <BootStrapButton
        className={buttonClass}
        onClick={disabled ? () => {} : onClick}
        {...props}
      >
        {text}
      </BootStrapButton>
    </div>
  );
};

export default Button;
