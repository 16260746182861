import { MONTHS } from './constants';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ENDPOINTS = {
  GETACTIVECOMPAIGN: 'getActiveCompaign',
  GETSUCCESSCOMPAIGN: 'getSuccessfullCompaign',
  CREATEDONATION: 'createDonation',
  CURRENCY: 'currency',
};

export { BASE_URL, ENDPOINTS };

export const getMonthName = (monthNumber: number) => {
  // Check if the provided number is a valid month number
  if (monthNumber >= 1 && monthNumber <= 12) {
    return MONTHS[monthNumber - 1]; // Arrays are zero-indexed
  } else {
    return 'Invalid month number';
  }
};

export const computeStep = (progress: any) => {
  let step = 13;
  if (progress >= 0 && progress <= 5) {
    step = 14;
  } else if (progress > 5 && progress <= 50) {
    step = 13;
  } else if (progress > 50 && progress <= 75) {
    step = 15;
  } else {
    step = 16;
  }
  return step;
};

export const capitalizeFirstLetter = (str: string) => {
  let words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
};

export const isNumericValue = (val: any) => {
  let isNumeric = false;
  const regex = /^[0-9]+(\.[0-9]+)?$/;
  const isEmpty = val === '';
  const isNumber = regex.test(val);
  if (isNumber || isEmpty) {
    isNumeric = true;
  }

  return isNumeric;
};

export const downloadFileFromURL = (url: string) => {
  if (url) {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'report.pdf'; // Set the filename for downloaded document
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
};

export const copyToClipboard = (text: string) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');

  document.body.removeChild(textarea);
};

export const formatAmount = (amount: any) => {
  if (amount) {
    const formattedAmount = amount.toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 0,
    });
    return formattedAmount;
  }

  return amount;
};

export const getFontSize = (
  amount: any,
  defaultFontSize: number,
  isHomePage: boolean
) => {
  const stringWithoutCommas = amount?.replace(/,\s*/g, '');
  let size = `${defaultFontSize}px`;

  if (stringWithoutCommas?.length >= 8) {
    size = isHomePage ? '22px' : `${defaultFontSize - 5}px`;
  } else if (stringWithoutCommas?.length === 7) {
    size = isHomePage ? '24px' : `${defaultFontSize - 4}px`;
  } else if (stringWithoutCommas?.length === 6) {
    size = isHomePage ? '26px' : `${defaultFontSize - 3}px`;
  } else if (stringWithoutCommas?.length === 5) {
    size = isHomePage ? '28px' : `${defaultFontSize - 2}px`;
  } else if (stringWithoutCommas?.length === 4) {
    size = isHomePage ? '29px' : `${defaultFontSize - 1}px`;
  } else if (stringWithoutCommas?.length < 4) {
    size = size;
  }

  return size;
};
