import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ServiceWorkerProvider } from './ServiceWorkerContext'; // Import the provider
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as ReactRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ServiceWorkerProvider>
      {' '}
      {/* Wrap your app in the ServiceWorkerProvider */}
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ReactRouter>
            <App />
          </ReactRouter>
        </PersistGate>
      </Provider>
    </ServiceWorkerProvider>
  </React.StrictMode>
);

window.addEventListener('load', () => {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker) {
          installingWorker.onstatechange = () => {
            if (
              installingWorker.state === 'installed' &&
              navigator.serviceWorker.controller
            ) {
              // Notify user about the update
              alert(
                "New version available! Please close this app and remove it from your recent apps. Then, reopen it to apply the latest updates. If you're using this as a standalone app from your home screen, please delete the app and then re-add it to your home screen to ensure the update takes effect."
              );
            }
          };
        }
      };
    })
    .catch((err) => {
      console.error('Service worker registration failed:', err);
    });
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
