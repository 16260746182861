import React from 'react';

const SelectDropDownSvg = ({ className }: any) => {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3554 1.10493C7.30892 1.05807 7.25361 1.02087 7.19269 0.995485C7.13176 0.9701 7.0664 0.957031 7.0004 0.957031C6.93439 0.957031 6.86904 0.9701 6.80811 0.995485C6.74718 1.02087 6.69188 1.05807 6.6454 1.10493L4.3554 3.39493C4.30892 3.44179 4.25361 3.47899 4.19269 3.50437C4.13176 3.52976 4.0664 3.54282 4.0004 3.54282C3.93439 3.54282 3.86904 3.52976 3.80811 3.50437C3.74718 3.47899 3.69188 3.44179 3.6454 3.39493L1.3554 1.10493C1.30891 1.05807 1.25361 1.02087 1.19268 0.995485C1.13176 0.9701 1.0664 0.957031 1.0004 0.957031C0.93439 0.957031 0.869038 0.9701 0.808108 0.995485C0.747178 1.02087 0.691878 1.05807 0.645396 1.10493C0.552271 1.19861 0.5 1.32534 0.5 1.45743C0.5 1.58952 0.552271 1.71625 0.645396 1.80993L2.9404 4.10492C3.22165 4.38582 3.6029 4.5436 4.0004 4.5436C4.3979 4.5436 4.77915 4.38582 5.0604 4.10492L7.3554 1.80993C7.44852 1.71625 7.50079 1.58952 7.50079 1.45743C7.50079 1.32534 7.44852 1.19861 7.3554 1.10493Z"
        fill="white"
      />
    </svg>
  );
};

export default SelectDropDownSvg;
