import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ServiceWorkerContextType {
  newVersionAvailable: boolean;
  showUpdate: () => void;
  hideUpdate: () => void;
}

interface ServiceWorkerProviderProps {
  children: ReactNode; // This specifies that children is of type ReactNode
}

const ServiceWorkerContext = createContext<ServiceWorkerContextType | undefined>(undefined);

export const useServiceWorker = (): ServiceWorkerContextType => {
  const context = useContext(ServiceWorkerContext);
  if (context === undefined) {
    throw new Error('useServiceWorker must be used within a ServiceWorkerProvider');
  }
  return context;
};

export const ServiceWorkerProvider: React.FC<ServiceWorkerProviderProps> = ({ children }) => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  const showUpdate = () => {
    setNewVersionAvailable(true);
  };

  const hideUpdate = () => {
    setNewVersionAvailable(false);
  };

  return (
    <ServiceWorkerContext.Provider value={{ newVersionAvailable, showUpdate, hideUpdate }}>
      {children}
    </ServiceWorkerContext.Provider>
  );
};
