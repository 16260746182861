import React from 'react';
import classes from './SalesSummary.module.scss';
import SummaryRow from './SummaryRow/SummaryRow';
import { useSelector } from 'react-redux';
import { COLORS, ROLES } from '../../utils/constants';
import Loader from '../common/Loader/Loader';

const SalesSummary = (props: any) => {
  const { summaryData, showSummary } = props;

  const user = useSelector((state: any) => state?.root?.user?.user);
  const { isLoading } = useSelector((state: any) => state?.root?.summary);
  const isEmployee = user?.role === ROLES.Employee;
  const isManager = user?.role === ROLES.Manager;
  const isGM = user?.role === ROLES.GM;

  const ifExists = (keyName: string, data: any) => {
    const keyArray = Object.keys(data);
    return keyArray.includes(keyName);
  };

  console.log('summaryData->', summaryData);

  return (
    <div
      className={`${classes.container} ${
        showSummary ? classes.transition : ''
      }`}
    >
      {isLoading && showSummary ? (
        <>
          <Loader containerClassName={classes.loader} />
        </>
      ) : (
        <>
          {summaryData?.map((item: any) => {
            const isTotalRow = isManager
              ? ifExists('staff_total_target', item)
                ? true
                : false
              : ifExists('total', item)
              ? true
              : false;

            const isDiffRow = isManager
              ? ifExists('staff_total_target_vs_store_total_target', item)
                ? true
                : false
              : ifExists('store_vs_country', item)
              ? true
              : false;

            const totalAmountFormatted = isManager
              ? item?.staff_total_target_formatted
              : item?.total_formatted;

            const diffAmount = isManager
              ? item?.staff_total_target_vs_store_total_target
              : item?.store_vs_country;

            const diffAmountFormatted = isManager
              ? item?.staff_total_target_vs_store_total_target_formatted
              : item?.store_vs_country_formatted;

            if (item?.staff_name) {
              return (
                <SummaryRow
                  description={item?.staff_name}
                  amount={item?.total_target_formatted}
                  amount_color={
                    item?.total_target === 0 ? COLORS.carrot : COLORS.gray
                  }
                />
              );
            } else if (isTotalRow) {
              return (
                <SummaryRow
                  isTotalRow
                  description="TOTAL"
                  amount={totalAmountFormatted}
                />
              );
            } else if (isDiffRow) {
              return (
                <SummaryRow
                  isDifferenceRow
                  description={
                    isManager ? 'Manager vs. Staff' : 'Stores vs. Country'
                  }
                  amount={diffAmountFormatted}
                  amount_color={
                    diffAmount < 0
                      ? COLORS.carrot
                      : diffAmount === 0
                      ? 'white'
                      : COLORS.lightgreen
                  }
                />
              );
            }
          })}
        </>
      )}
    </div>
  );
};

export default SalesSummary;
