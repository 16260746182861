import React from 'react';

const ArrownRightSvg = ({ className, onClick }: any) => {
  return (
    <div className={className} style={{ cursor: 'pointer' }} onClick={onClick}>
      <svg
        width="10"
        height="18"
        viewBox="0 0 10 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L8.29289 8.29289C8.68342 8.68342 8.68342 9.31658 8.29289 9.70711L1 17"
          stroke="#E0E0E6"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};

export default ArrownRightSvg;
