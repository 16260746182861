import React from 'react';
import classes from './WelcomeScreen.module.scss';
import Button from '../../../components/common/Button/Button';
import logo from '../../../assets/png/logo.png';
import { useNavigate } from 'react-router-dom';

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/login');
  };
  return (
    <>
      <div className={classes.container}>
        <img src={logo} className={classes.logo} />
        <div className={classes.content_flex}>
          <p className={classes.heading}>Lets make a difference today!</p>
          <Button
            onClick={handleClick}
            buttonClassName={classes.get_started_btn}
            text="Get Started"
          />
          {/* <Button
            onClick={handleClick}
            buttonClassName={classes.account_btn}
            text="I have an account"
          /> */}
        </div>
      </div>
    </>
  );
};

export default WelcomeScreen;
