import React from 'react';
import classes from './StuffCard.module.scss';
import { ICard } from '../../../models/ICard';
import ProgressBar from '@ramonak/react-progress-bar';
const StuffCard = ({
  cardClassName,
  src,
  title,
  containerClassName,
  ratio,
  percentage,
  completed,
  color,
  amount,
  heading,
  totalRatio,
  totalPercentage,
  totalSalesRatio,
  totalSalesPercentage,
  avgSale,
  avgSalePercentage,
  ...props
}: ICard) => {
  let cardClass = classes.card;
  let containerClass = classes.container;
  if (cardClassName) {
    cardClass = `${cardClassName} ${cardClass}`;
  }
  if (containerClassName) {
    containerClass = `${containerClass} ${containerClassName}`;
  }
  return (
    <div className={containerClass} {...props}>
      <div className={classes.card_heading}>{heading}</div>
      <div className={cardClass}>
        <div className={classes.row}>
          <div className={classes.description}>
            {title === 'OTO' ? `Total ${title}'s` : `Total ${title}s`}
          </div>
          <div className={classes.amounts}>
            <div className={classes.ratio}>{totalRatio}</div>
            <div className={classes.percentage}>{totalPercentage}</div>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.description}>{heading === 'PEKABOO' ? 'Total Sales in Units' : 'Total Sales'}</div>
          <div className={classes.amounts}>
            <div className={classes.ratio}>{totalSalesRatio}</div>
            <div className={classes.percentage}>{totalSalesPercentage}</div>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.description}>
            {heading === 'PEKABOO' ? 'Conversion Ratio' : 'AVG. sale per OTO'}
          </div>
          <div className={classes.amounts}>
            <div className={classes.ratio}>{avgSale}</div>
            <div className={classes.percentage}>{avgSalePercentage}</div>
          </div>
        </div>
      </div>
      {/* <div className={classes.progressbar}>
        <ProgressBar
          height="3px"
          completed={completed ?? 0}
          isLabelVisible={false}
          baseBgColor="#4E4E61"
          bgColor={color}
        />
      </div> */}
    </div>
  );
};

export default StuffCard;
