import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  getProductGoalsStatsService,
  getUserGoalsApiCall,
} from '../../services/general.services';
import { getGoalsByMonthYearProduct } from '../../services/general.services';

export const getGoalsByMonthAndYearAsync = createAsyncThunk<any, any, any>(
  'goals/getGoalsByMonthAndYearAsync',
  async (params: string, { rejectWithValue }) => {
    try {
      const response = await getGoalsByMonthYearProduct(params); // Assuming fetchUser returns a Promise
      if (response) {
        return response?.data?.goals;
      }
    } catch (error: any) {
      return rejectWithValue(error); // Assuming the user data is in response.data
    }
  }
);

export const previousMonthGoalsAsyncApiCall = createAsyncThunk<any, any, any>(
  'goals/previousMonthGoalsAsyncApiCall',
  async (params: string, { rejectWithValue }) => {
    try {
      const response = await getGoalsByMonthYearProduct(params); // Assuming fetchUser returns a Promise
      if (response) {
        return response?.data?.goals;
      }
    } catch (error: any) {
      return rejectWithValue(error); // Assuming the user data is in response.data
    }
  }
);

export const getUserGoalsAsyncThunk = createAsyncThunk<any, any, any>(
  'goals/getUserGoalsAsyncApiCall',
  async (params: string, { rejectWithValue }) => {
    try {
      const response = await getUserGoalsApiCall(params); // Assuming fetchUser returns a Promise
      if (response) {
        return response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error); // Assuming the user data is in response.data
    }
  }
);
