import React, { useEffect, useState } from 'react';
import classes from './Home.module.scss';
import 'react-circular-progressbar/dist/styles.css';
import { CircleProgressBar } from '../../components/circleProgressBar/CircleProgressBar';
import backIcon from '../../assets/png/backIcon.png';
import PresentBox from '../../components/presentBox/PresentBox';
import shoppingBagImg from '../../assets/png/shoppingBag.png';
import usersImage from '../../assets/png/users.png';
import thumbUp from '../../assets/png/thumbUp.png';
import { useDispatch, useSelector } from 'react-redux';
import { getProductGoalsStatsAsync } from '../../redux/actions/productGoalsStats.action';
import { formatAmount, getFontSize, getMonthName } from '../../utils/helpers';
import {
  getGmMessage,
  logAchievement,
  undoLastAchievement,
} from '../../services/general.services';
import { toast } from 'react-toastify';
import { ISelectedItemsState } from '../../models/IHome';
import Modal from '../../components/common/Modal/Modal';
import OneToOneSalesModal from './OneToOneSalesModal';
import useLogout from '../../utils/hooks/useLogout';
import Loader from '../../components/common/Loader/Loader';
import LogoutSvg from '../../assets/svg/LogoutSvg';
import LogoutModal from '../../components/common/LogoutModal/LogoutModal';
import UndoSvg from '../../assets/svg/UndoSvg';
import { Input } from '../../components/common/Input/Input';
import Button from '../../components/common/Button/Button';
import { COLORS, ROLES } from '../../utils/constants';
import NotificationsScreen from '../Notifications/Notifications';
import UserSvg from '../../assets/svg/UserSvg';
import ShoppingBagSvg from '../../assets/svg/ShoppingBagSvg';
import CalendarSvg from '../../assets/svg/CalendarIcon';
import UsersSvg from '../../assets/svg/UsersSvg';
import { fetchAllNotificationsAsync } from '../../redux/actions/notifications.action';
import moment from 'moment';

const Home = (): JSX.Element => {
  const { logout } = useLogout();
  const [date, setDate] = useState<any>(moment());
  const { stats, isLoading } = useSelector((state: any) => state.root.stats);
  const { notifications, isLoading: loadingNotifications } = useSelector(
    (state: any) => state.root.notifications
  );
  const { user } = useSelector((state: any) => state.root.user);
  const isManager = user?.role === ROLES.Manager;
  const isEmployee = user?.role === ROLES.Employee;
  const isGM = user?.role === ROLES.GM;
  const [open, setOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [gmMessage, setGmMessage] = useState('');
  const {
    current_month,
    total_target,
    total_achieved,
    total_percentage_achieved,
    user_info,
    stats_by_type,
  } = stats;

  const { one_to_one_sale, product_presentation, total_sale } =
    stats_by_type || {};
  const [selectedItems, setSelectedItems] =
    useState<null | ISelectedItemsState>(null);

  console.log('selectedItems=>', selectedItems);
  const [tagline, setTagline] = useState<null | string>(
    'Your goals are on track'
  );
  const [amount, setAmount] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<any>(null);
  const [amountError, setAmountError] = useState<null | string>(null);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(getProductGoalsStatsAsync(user?.active_product_id));
  }, []);

  useEffect(() => {
    const managerData = { user_id: selectedUserId };
    const formatedDate = moment.utc(date).format('YYYY-MM-DD');
    const params = {
      date: formatedDate,
      ...(isManager && managerData),
    };
    dispatch(fetchAllNotificationsAsync(params));
  }, [date, selectedUserId]);

  useEffect(() => {
    if (selectedItems) {
      const goalTypeObject = goalType(selectedItems.name || '');
      setSelectedItems(goalTypeObject);
    }
  }, [stats]);

  useEffect(() => {
    if (isEmployee) {
      getGmMessage().then((res) => {
        setGmMessage(res?.data?.gm_message);
      });
    }
  }, []);

  const isAnyItemSelected = selectedItems != null;

  const handleSelect = (name: string, amount?: number) => {
    const goalTypeObject = goalType(name);
    let data = {
      product_id: user?.active_product_id,
      goal_type: goalTypeObject?.name,
      month: current_month,
      target: goalTypeObject?.today_achievements ?? '',
      amount: amount ?? '',
      year: '2024',
    };
    logAchievement(data)
      .then((res) => {
        dispatch(getProductGoalsStatsAsync(user?.active_product_id));
        setSelectedItems(goalTypeObject);
        // setSelectedItems(null);
        setAmount('');
        toast?.success(res?.data?.message);
        setOpen(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleSelectItem = (name: string, amount?: number) => {
    const goalTypeObject = goalType(name);
    setSelectedItems(goalTypeObject);
  };

  const goalType = (name: string) => {
    if (name === 'product_presentation') {
      return {
        ...stats_by_type?.product_presentation,
        name: 'product_presentation',
        animation: true,
      };
    }
    if (name === 'total_sale') {
      return {
        ...stats_by_type?.total_sale,
        name: 'total_sale',
        animation: true,
      };
    }
    if (name === 'one_to_one_sale') {
      return {
        ...stats_by_type?.one_to_one_sale,
        name: 'one_to_one_sale',
        animation: true,
      };
    }
  };

  const getName = (name?: string) => {
    let n = '';
    switch (name) {
      case 'one_to_one_sale':
        n = 'OTO SALES';
        break;
      case 'product_presentation':
        n = 'PEKABOO';
        break;
      case 'total_sale':
        n = 'TOTAL SALES';
        break;
      default:
        n = n;
    }
    return n;
  };

  const getColor = (name?: string) => {
    let n = '';
    switch (name) {
      case 'one_to_one_sale':
        n = '#f9b949';
        break;
      case 'product_presentation':
        n = '#00fad9';
        break;
      case 'total_sale':
        n = '#ff7966';
        break;
      default:
        n = n;
    }
    return n;
  };

  const getAmount = (name?: string) => {
    if (name === 'product_presentation') {
      return `${selectedItems?.today_click_achievements}/${selectedItems?.today_click_target} P`;
    } else if (name === 'total_sale') {
      return `€${selectedItems?.today_amount_achieved}`;
    } else {
      return `€${selectedItems?.today_amount_achieved}`;
    }
  };

  const getAmountForFontSize = (name?: string) => {
    if (name === 'product_presentation') {
      return `00000`;
    } else if (name === 'total_sale') {
      return `${selectedItems?.today_amount_achieved}`;
    } else {
      return `${selectedItems?.today_amount_achieved}`;
    }
  };

  const getProgressPercentage = (name?: string) => {
    if (name === 'product_presentation') {
      return `${selectedItems?.today_click_achievement_percentage}`;
    } else if (name === 'total_sale') {
      return `${selectedItems?.today_amount_achievement_percentage}`;
    } else {
      return `${selectedItems?.today_amount_achievement_percentage}`;
    }
  };

  const handleUndo = () => {
    let data = {
      month: current_month,
      year: '2024',
    };
    undoLastAchievement(data)
      .then((res) => {
        dispatch(getProductGoalsStatsAsync(user?.active_product_id));
        // setSelectedItems(null);
        toast?.success(res?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handleLogout = () => {
    setLogoutModalOpen(true);
  };

  const handleRegisterMeeting = () => {
    handleSelect('one_to_one_sale', 0);
  };

  const handleRegisterTotalSaleAmount = () => {
    if (amount.length === 0) {
      setAmountError('This is required');
      return;
    }
    handleSelect('total_sale', Number(amount));
  };

  const handleRegisterPekabooAmount = () => {
    // if (amount.length === 0) {
    //   setAmountError('This is required');
    //   return;
    // }
    // handleSelect('product_presentation', Number(amount));
    handleSelect('product_presentation', 1);
  };

  const handleRegisterAmount = () => {
    if (amount.length === 0) {
      setAmountError('This is required');
      return;
    }
    handleSelect('one_to_one_sale', Number(amount));
  };
  const handleRegisterPresentation = () => {
    handleSelect('product_presentation', 0);
  };

  const handleAmountChange = (e: any) => {
    setAmountError(null);
    const val = e.target.value;
    const regex = /^[0-9\b]+$/;
    const isNumber = regex.test(val);
    const isEmpty = val === '';
    if (isEmpty || isNumber) {
      setAmount(val);
    }
  };

  const isOneToOneBoxSelected = selectedItems?.name === 'one_to_one_sale';
  const isPekabooBoxSelected = selectedItems?.name === 'product_presentation';

  console.log(
    'isOneToOneBoxSelected->>isPekabooBoxSelected->',
    isOneToOneBoxSelected,
    isPekabooBoxSelected,
    selectedItems
  );

  if (isManager || isGM) {
    return (
      <NotificationsScreen
        notifications={notifications}
        isLoading={loadingNotifications}
        date={date}
        setDate={setDate}
        setSelectedUserId={setSelectedUserId}
      />
    );
  }

  return (
    <div
      className={`${classes.container_main} ${
        isPekabooBoxSelected ? `${classes.pekaboo_main}` : ''
      }`}
    >
      <div className={classes.main}>
        <button className={classes.logout_btn} onClick={handleLogout}>
          Logout
          <LogoutSvg />
        </button>

        {!isLoading && (
          <div className={classes.user_name_container}>
            <div className={classes.user_name}>{user_info?.name}</div>
          </div>
        )}

        {isEmployee && (
          <div className={classes.back_btn_wrapper} onClick={handleUndo}>
            <UndoSvg />
            <p>Undo</p>
          </div>
        )}

        <div className={classes.container}>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <div className={classes.circle_progress_bar_container}>
                <CircleProgressBar
                  date={isAnyItemSelected ? 'TODAY' : ''}
                  role={user?.role}
                  ratio={`${total_achieved} / ${total_target}`}
                  btnText={user_info?.name}
                  titleText={
                    user?.role === 'GM'
                      ? user?.country_name
                      : `${user_info?.city}, ${user_info?.store}`
                  }
                  percentage={`${total_percentage_achieved}%`}
                  progress={getProgressPercentage(selectedItems?.name)}
                  progressBarcolor={
                    isAnyItemSelected
                      ? getColor(selectedItems?.name)
                      : '#adadad33'
                  }
                  ratingClassName={
                    total_percentage_achieved > 99
                      ? classes.green_rating
                      : total_percentage_achieved > 50
                      ? classes.main_rating
                      : classes.red_rating
                  }
                  selectedItemName={getName(selectedItems?.name)}
                  selectedItemNameColor={getColor(selectedItems?.name)}
                  amount={getAmount(selectedItems?.name)}
                  amountStyles={{
                    fontSize: getFontSize(
                      getAmountForFontSize(selectedItems?.name),
                      40,
                      true
                    ),
                    lineHeight: isPekabooBoxSelected ? '32px' : '',
                  }}
                  meetingsCount={
                    isOneToOneBoxSelected
                      ? selectedItems?.today_click_achievements
                      : ''
                  }
                  peekabooTotalSales={
                    isPekabooBoxSelected
                      ? `${selectedItems?.today_amount_achieved_clean}/${selectedItems?.today_amount_target_clean} U`
                      : ''
                  }
                />
              </div>

              <div className={classes.cards}>
                <PresentBox
                  onClick={() => {
                    handleSelectItem('one_to_one_sale');
                  }}
                  containerClassName={
                    selectedItems?.name === 'one_to_one_sale' &&
                    selectedItems?.animation
                      ? classes.select_container_class_onetoone
                      : classes.container_class
                  }
                  borderColor={
                    selectedItems?.name === 'one_to_one_sale'
                      ? ''
                      : COLORS.yellow
                  }
                  ratio={`${stats_by_type?.one_to_one_sale?.today_achievements}/${stats_by_type?.one_to_one_sale?.daily_target}`}
                  icon={<UsersSvg />}
                  text={'OTO'}
                  value={`€ ${one_to_one_sale?.today_amount_target}`}
                  valueColor={COLORS.yellow}
                />
                <PresentBox
                  onClick={() => {
                    handleSelectItem('product_presentation');
                  }}
                  containerClassName={
                    selectedItems?.name === 'product_presentation' &&
                    selectedItems?.animation
                      ? classes.select_container_class_presentation
                      : classes.container_class
                  }
                  borderColor={
                    selectedItems?.name === 'product_presentation'
                      ? ''
                      : COLORS.lightgreen
                  }
                  ratio={`${stats_by_type?.product_presentation?.today_achievements}/${stats_by_type?.product_presentation?.daily_target}`}
                  icon={<ShoppingBagSvg />}
                  text={'PEEKABOO'}
                  value={`${product_presentation?.today_click_target} P - ${product_presentation?.today_amount_target} U`}
                  valueColor={COLORS.lightgreen}
                />
                <PresentBox
                  onClick={() => {
                    handleSelectItem('total_sale');
                  }}
                  containerClassName={
                    selectedItems?.name === 'total_sale' &&
                    selectedItems?.animation
                      ? classes.select_container_class_sale
                      : classes.container_class
                  }
                  borderColor={
                    selectedItems?.name === 'total_sale' ? '' : COLORS.carrot
                  }
                  ratio={`${stats_by_type?.total_sale?.today_achievements}/${stats_by_type?.total_sale?.daily_target}`}
                  icon={<UserSvg />}
                  text={'TOTAL SALES'}
                  value={`€ ${total_sale?.today_amount_target}`}
                  valueColor={COLORS.carrot}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {isAnyItemSelected && (
        <div className={classes.input_section}>
          {!isPekabooBoxSelected && (
            <Input
              containerClassName={classes.input_container}
              inputClassName={classes.input_field}
              type="text"
              placeholder="Enter Amount"
              onChange={handleAmountChange}
              value={amount}
              error={amountError}
            />
          )}
          {selectedItems?.name === 'one_to_one_sale' && (
            <div className={classes.buttons}>
              <Button
                containerClassName={classes.container_btn}
                buttonClassName={classes.sale_btn}
                text="Register Amount"
                onClick={handleRegisterAmount}
              />
              <Button
                containerClassName={classes.container_btn}
                buttonClassName={classes.meeting_btn}
                text="Register OTO"
                onClick={handleRegisterMeeting}
              />
            </div>
          )}
          {selectedItems?.name === 'product_presentation' && (
            <div className={classes.buttons}>
              <Button
                containerClassName={classes.container_btn}
                buttonClassName={classes.sale_btn_2}
                text="Register Unit Sold"
                onClick={handleRegisterPekabooAmount}
              />
              <Button
                containerClassName={classes.container_btn}
                buttonClassName={classes.presentation_btn}
                text="Register Presentation"
                onClick={handleRegisterPresentation}
              />
            </div>
          )}
          {selectedItems?.name === 'total_sale' && (
            <div className={classes.buttons}>
              <Button
                containerClassName={classes.allothersale_btn_container}
                buttonClassName={classes.allothersale_btn}
                text="Register Amount"
                onClick={handleRegisterTotalSaleAmount}
              />
            </div>
          )}
        </div>
      )}
      {gmMessage.length > 0 && (
        <div className={classes.highlight}>
          {gmMessage}
          {/* <span>
            <img src={thumbUp} width="14.33px" height="16px" />
          </span> */}
        </div>
      )}

      {open && (
        <Modal closeButton={false} show={open}>
          <OneToOneSalesModal setOpen={setOpen} handleSelect={handleSelect} />
        </Modal>
      )}
      {logoutModalOpen && (
        <LogoutModal
          isOpen={logoutModalOpen}
          setIsOpen={setLogoutModalOpen}
          handleLogout={logout}
        />
      )}
    </div>
  );
};
export default Home;
