import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import classes from './CircleProgressBar.module.scss';
import CirclePng from '../../assets/png/Ellipse.png';
import { PartialCircleProgressBar } from '../partialCircleProgressBar/PartialCircleProgressBar';
import { Carousel, CarouselItem, Dropdown } from 'react-bootstrap';
import arrowLeft from '../../assets/png/arrowLeft.png';
import arrowRight from '../../assets/png/arrowRight.png';
import Button from '../common/Button/Button';
import { DottedCircleBar } from '../DottedCircleBar/DottedCircleBar';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import Select from '../common/ReactSelect/ReactSelect';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { COLORS } from '../../utils/constants';
export const CircleProgressBar = ({
  ratingClassName,
  progress,
  percentage,
  btnText,
  carousalItems,
  titleText,
  progressBarClassName,
  date,
  ratio,
  dropdownItems,
  dropdownToogleText,
  progressBarcolor = '#F9B949',
  onChange,
  onCarousalSelect,
  selectedItem,
  carousalActiveIndex,
  selectedItemName,
  amount,
  selectedItemNameColor,
  role,
  totalAmountAchieved,
  totalAmountTarget,
  amountStyles,
  meetingsCount,
  peekabooTotalSales,
}: {
  ratingClassName?: string;
  progress?: string | number;
  percentage?: string | number;
  btnText?: string;
  carousalItems?: string[];
  titleText?: string;
  progressBarClassName?: string;
  date?: string;
  ratio?: string;
  progressBarcolor?: string;
  dropdownItems?: any[];
  dropdownToogleText?: string;
  onChange?: (event: any) => void;
  onCarousalSelect?: (event: any) => void;
  carousalActiveIndex?: number;
  selectedItem?: string;
  selectedItemName?: string;
  amount?: string;
  selectedItemNameColor?: string;
  role?: string;
  totalAmountAchieved?: string;
  totalAmountTarget?: string;
  amountStyles?: any;
  meetingsCount?: number | string;
  peekabooTotalSales?: string;
}) => {
  const { pathname } = useLocation();
  const isHomePage = pathname.includes('home');
  const isStatsStorePage = pathname.includes('stats-store');
  const { notifications } = useSelector(
    (state: any) => state?.root?.notifications
  );
  let ratingClass = classes.rating;
  let progressClass = classes.progress_bar;
  if (ratingClassName) {
    ratingClass = `${ratingClass} ${ratingClassName}`;
  }
  if (progressBarClassName) {
    progressClass = `${progressClass} ${progressBarClassName}`;
  }

  const isFirst = carousalActiveIndex === 0;

  const isLast = carousalItems?.length
    ? carousalActiveIndex === carousalItems?.length - 1
    : false;

  const getClassName = () => {
    let cls = '';
    if (isHomePage && selectedItemName && selectedItemName === 'PEKABOO') {
      cls = `${classes.content_container} ${classes.home_page} ${classes.home_page_selecteditem} ${classes.home_page_selecteditem_pekaboo}`;
    } else if (
      isHomePage &&
      selectedItemName &&
      selectedItemName === 'TOTAL SALES'
    ) {
      cls = `${classes.content_container} ${classes.home_page} ${classes.home_page_selecteditem} ${classes.home_page_selecteditem_totalsale}`;
    } else if (isHomePage && selectedItemName) {
      cls = `${classes.content_container} ${classes.home_page} ${classes.home_page_selecteditem}`;
    } else if (isHomePage) {
      cls = `${classes.content_container} ${classes.home_page}`;
    } else {
      cls = classes.content_container;
    }

    return cls;
  };

  const getColor = (type: string) => {
    let clr = '';
    switch (type) {
      case 'product_presentation':
        clr = COLORS.lightgreen;
        break;
      case 'one_to_one_sale':
        clr = COLORS.yellow;
        break;
      case 'total_sale':
        clr = COLORS.carrot;
        break;
      case 'avr_one_to_one_sale':
        clr = COLORS.carrot;
        break;
      case 'active_in_app':
        clr = COLORS.white;
        break;
      default:
        clr = COLORS.white;
    }
    return clr;
  };

  const getFontSize = (message: string) => {
    let fontsize = '16px';
    const msgLength = message?.length;
    switch (true) {
      case msgLength > 200:
        fontsize = '6px';
        break;
      case msgLength <= 200 && msgLength > 175:
        fontsize = '7px';
        break;
      case msgLength <= 175 && msgLength > 120:
        fontsize = '8px';
        break;
      case msgLength <= 120 && msgLength > 90:
        fontsize = '9px';
        break;
      case msgLength <= 90 && msgLength > 80:
        fontsize = '10px';
        break;
      case msgLength <= 80 && msgLength > 70:
        fontsize = '11px';
        break;
      case msgLength <= 70 && msgLength > 55:
        fontsize = '12px';
        break;
      case msgLength <= 55 && msgLength > 45:
        fontsize = '13px';
        break;
      case msgLength <= 45 && msgLength > 35:
        fontsize = '14px';
        break;
      case msgLength <= 35 && msgLength > 30:
        fontsize = '15px';
        break;
      case msgLength <= 30 && msgLength > 25:
        fontsize = '16px';
        break;
      default:
        fontsize = fontsize;
    }
    return fontsize;
  };

  return (
    <div className={classes.container}>
      <div className={progressClass}>
        <PartialCircleProgressBar
          progress={progress}
          radius={100}
          strokeWidth={8}
          color={progressBarcolor}
        />
        <div className={classes.semi_circle}>
          <DottedCircleBar
            radius={80}
            strokeWidth={5}
            color="#4e4e61"
            dotLength={0}
            innerDistance={23}
          />
        </div>
      </div>
      <div className={getClassName()}>
        {!isStatsStorePage && <div className={classes.date}></div>}
        {/* <div className={classes.percentage}>{percentage}</div> */}
        {isHomePage && (
          <div className={classes.content}>
            {selectedItemName ? (
              <div className={classes.selecteditem_container}>
                <div className={classes.date}>{date}</div>
                <div>
                  <div className={classes.amount} style={{ ...amountStyles }}>
                    {amount}
                  </div>
                  {meetingsCount && (
                    <div className={classes.meetings_count}>
                      {meetingsCount}{' '}
                      {Number(meetingsCount) > 1 ? 'meetings' : 'meeting'}
                    </div>
                  )}
                  {peekabooTotalSales && (
                    <div className={classes.amount} style={{ ...amountStyles }}>
                      {peekabooTotalSales}
                    </div>
                  )}
                </div>
                <div
                  className={classes.selecteditemname}
                  style={{ color: selectedItemNameColor }}
                >
                  {selectedItemName}
                </div>
              </div>
            ) : (
              <div
                className={classes.tagline}
                style={{
                  fontSize: getFontSize(notifications[0]?.message),
                  // color: getColor(notifications[0]?.goal_type),
                }}
              >
                {notifications?.length > 0 ? notifications[0]?.message : ''}
              </div>
            )}
          </div>
        )}
        {isStatsStorePage && (
          <div className={classes.totalsale_container}>
            <div className={classes.month_text}>{date?.toUpperCase()}</div>
            <div className={classes.heading}>TOTAL SALES</div>
            <div>
              <div
                className={classes.amount_achieved}
                style={{ ...amountStyles }}
              >
                {totalAmountAchieved}
              </div>
              <div
                className={
                  Number(percentage) >= 100
                    ? classes.percentage_green
                    : classes.percentage_carrot
                }
              >
                {percentage}%
              </div>
            </div>
            <div className={classes.amount_target}>
              <div>TARGET</div>
              <div>{totalAmountTarget}</div>
            </div>
          </div>
        )}

        {/* <div className={ratingClass}>{ratio}</div> */}
        {isStatsStorePage && (
          <div className={classes.carousel_container}>
            <Carousel
              className={classes.carousal}
              onSelect={onCarousalSelect}
              activeIndex={carousalActiveIndex}
              prevIcon={<img src={arrowLeft} width="24px" height="24px" />}
              nextIcon={<img src={arrowRight} width="24px" height="24px" />}
              slide={false}
              indicators={false}
              interval={null}
            >
              {carousalItems &&
                carousalItems?.map((item) => {
                  return (
                    <CarouselItem className={classes.item}>{item}</CarouselItem>
                  );
                })}
            </Carousel>
          </div>
        )}
        {/* {titleText && (
          <div className={!btnText ? classes.title : ''}>{titleText}</div>
        )} */}
      </div>
    </div>
  );
};
