import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import Login from '../pages/auth/login/Login';
import Public from './Public';
import Private from './Private';
import WelcomeScreen from '../pages/auth/welcome/WelcomeScreen';
import Home from '../pages/home/Home';
import Stats from '../pages/Stats/Stats';
import { StatsStore } from '../pages/StatsStore/StatsStore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Routes = (): JSX.Element => {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        theme="dark"
      />
      <ReactRoutes>
        <Route element={<Public />}>
          <Route index element={<WelcomeScreen />} />
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<Private />}>
          <Route element={<Layout />}>
            <Route path="/stats" element={<Stats />} />
            <Route path="/home" element={<Home />} />
            <Route path="/stats-store" element={<StatsStore />} />
          </Route>
        </Route>
        <Route path="*" element={<div>404 page not found</div>} />
      </ReactRoutes>
    </>
  );
};
export default Routes;
