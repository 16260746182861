import React from 'react';
import { Oval } from 'react-loader-spinner';
import classes from './Loader.module.scss';

function Loader({
  height = '80',
  width = '80',
  color = 'white',
  strokeWidth = '5',
  secondaryColor = '#414152',
  wrapperStyle = {},
  wrapperClass,
  containerClassName,
}: {
  height?: string;
  width?: string;
  color?: string;
  strokeWidth?: string;
  secondaryColor?: string;
  wrapperStyle?: object;
  wrapperClass?: string;
  containerClassName?: string;
}) {
  let wrapperClassName = classes.wrapperClass;
  let containerClass = classes.container;
  if (wrapperClass) {
    wrapperClassName = `${wrapperClassName} ${wrapperClass}`;
  }
  if (containerClassName) {
    containerClass = `${containerClass} ${containerClassName}`;
  }
  return (
    <div className={containerClass}>
      <Oval
        visible={true}
        height={height}
        width={width}
        color={color}
        strokeWidth={strokeWidth}
        secondaryColor={secondaryColor}
        ariaLabel="loading"
        wrapperStyle={{ ...wrapperStyle }}
        wrapperClass={wrapperClassName}
      />
    </div>
  );
}

export default Loader;
