import { createSlice } from '@reduxjs/toolkit';
import { stat } from 'fs';
import { fetchSalesTargetSummaryAsync } from '../actions/summary.action';

const initialState = {
  isLoading: false,
  error: '',
  summary: [],
};

const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    resetSummary: () => initialState,
    setSummary: (state, action) => {
      state.summary = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesTargetSummaryAsync.pending, (state, action) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(fetchSalesTargetSummaryAsync.fulfilled, (state, action) => {
        state.summary = action.payload;
        state.isLoading = false;
        state.error = ''; // Clear previous errors on pending
      })
      .addCase(fetchSalesTargetSummaryAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action?.payload
          ? action?.payload
          : action?.payload?.message
          ? action?.payload?.message
          : 'An error occurred';
      });
  },
});

export const { resetSummary, setSummary } = summarySlice.actions;
export default summarySlice.reducer;
