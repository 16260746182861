import { HTTP_CLIENT } from '../utils/config';

export const loginService = (email: string, password: string) => {
  return HTTP_CLIENT.post(`/login?email=${email}&password=${password}`);
};

export const getUsers = (params?: any) => {
  const { city_id, store_id } = params || {};
  return HTTP_CLIENT.get(
    `/users${city_id ? `?city_id=${city_id}` : ''}${
      store_id && city_id
        ? `&store_id=${store_id}`
        : store_id && !city_id
        ? `?store_id=${store_id}`
        : ''
    }`
  );
};

export const getCities = () => {
  return HTTP_CLIENT.get(`/cities`);
};
export const getStores = (params: any) => {
  const { city_id } = params;
  return HTTP_CLIENT.get(`/stores${city_id ? `?city_id=${city_id}` : ''}`);
};

export const getProductGoalsStatsService = (goalsId: string) => {
  return HTTP_CLIENT.get(`/goals/stats/${goalsId}`);
};

export const getActiveProduct = () => {
  return HTTP_CLIENT.get('/user/active-product');
};

export const setGoal = (params: any) => {
  const { product_id, goal_type, target, month, year } = params;
  return HTTP_CLIENT.post(
    `set-goals?product_id=${product_id}&goal_type=${goal_type}&target=${target}&month=${month}&year=${year}`
  );
};

export const logAchievement = (params: any) => {
  const { product_id, goal_type, month, year, amount } = params;
  return HTTP_CLIENT.post(
    `goals/log-achievement?product_id=${product_id}&goal_type=${goal_type}&month=${month}&year=${year}${
      amount || amount === 0 ? `&amount=${amount}` : ''
    }`
  );
};

export const undoLastAchievement = (params: any) => {
  const { product_id, goal_type, target, month, year } = params;
  return HTTP_CLIENT.post(`goals/undo-achievement?month=${month}&year=${year}`);
};

export const getGoalsByMonthYearProduct = (params: any) => {
  const { product_id, month, year } = params;
  return HTTP_CLIENT.get(
    `goals/month-year-product?product_id=${product_id}&month=${month}&year=${year}`
  );
};

export const getNotifications = (params: any) => {
  const { date, user_id } = params;
  return HTTP_CLIENT.get(
    `notifications?date=${date}${user_id ? `&user_id=${user_id}` : ''}`
  );
};

export const getCountrySalesTarget = (params: any) => {
  const { month, year } = params;
  return HTTP_CLIENT.get(`countries/sales-target?month=${month}&year=${year}`);
};

export const setCountrySalesTarget = (params: any) => {
  const { month, year, amount } = params;
  return HTTP_CLIENT.post(
    `countries/sales-target?month=${month}&year=${year}&average_one_to_one_sales_target=${amount}`
  );
};

export const getStoreSalesTarget = (params: any) => {
  const { store_id, city_id, country_id, month, year } = params;
  return HTTP_CLIENT.get(
    `stores/sales-target?month=${month}&year=${year}${
      store_id ? `&store_id=${store_id}` : ''
    }${city_id ? `&city_id=${city_id}` : ''}${
      country_id ? `&country_id=${country_id}` : ''
    }`
  );
};

export const setStoreSalesTarget = (params: any) => {
  const { store_id, city_id, country_id, month, year, amount } = params;
  return HTTP_CLIENT.post(
    `stores/sales-target?month=${month}&year=${year}&total_sales_target=${amount}${
      store_id ? `&store_id=${store_id}` : ''
    }${city_id ? `&city_id=${city_id}` : ''}${
      country_id ? `&country_id=${country_id}` : ''
    }`
  );
};

export const getUserGoalsApiCall = (params: any) => {
  const { user_id, month, year, store_id, city_id, today } = params;
  return HTTP_CLIENT.get(
    `/goals/user-goals-stats${today ? '/today' : ''}?${
      user_id ? `user_id=${user_id}&` : ''
    }month=${month}&year=${year}${store_id ? `&store_id=${store_id}` : ''}${
      city_id ? `&city_id=${city_id}` : ''
    }`
  );
};

export const postGoalsApiCall = (params: any) => {
  return HTTP_CLIENT.post('/set-goals', params);
};

export const getGmMessage = () => {
  return HTTP_CLIENT.get('/fetch-gm-message');
};

export const saveGmMessage = (props: any) => {
  const { message, countryId } = props;
  return HTTP_CLIENT.post(
    `countries/gm-message?gm_message=${message}&country_id=${countryId}`
  );
};

export const getPdfReport = (params: any) => {
  const { month, year, city_id, store_id, user_id } = params;
  return HTTP_CLIENT.get(
    `pdf-report?month=${month}&year=${year}${
      city_id ? `&city_id=${city_id}` : ''
    }${store_id ? `&store_id=${store_id}` : ''}${
      user_id ? `&user_id=${user_id}` : ''
    }`
  );
};

export const getSalesTargetSummary = (params: any) => {
  const { month, year, city_id, store_id, country_id, total_sales_target } =
    params;
  return HTTP_CLIENT.post(
    `stores/sales-target-summary?month=${month}&year=${year}${
      city_id ? `&city_id=${city_id}` : ''
    }${store_id ? `&store_id=${store_id}` : ''}${
      country_id ? `&country_id=${country_id}` : ''
    }${total_sales_target ? `&total_sales_target=${total_sales_target}` : ''}`
  );
};
