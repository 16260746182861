import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../models/reducers/IUser";

const initialState: IUser = {
  user: "",
  isLoading: false,
  error: "",
};

export const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    resetUser: () => initialState,
  },
});

export const { resetUser, setUser } = userReducer.actions;
export default userReducer.reducer;
