import React from 'react';
import classes from './NotificationCard.module.scss';
import { INotification } from '../../models/INotifications';

const NotificationCard = ({
  notification,
  mainClassName,
  circleClassName,
}: {
  notification: INotification;
  mainClassName?: string;
  circleClassName?: string;
}) => {
  let mainClass = classes.main;
  let circleClass = classes.notification_circle;
  if (circleClassName) {
    circleClass = `${circleClass} ${circleClassName}`;
  }
  if (mainClassName) {
    mainClass = `${mainClass} ${mainClassName}`;
  }
  return (
    <div className={mainClass}>
      <div className={classes.sender_info}>
        <div className={circleClass}></div>
        <div className={classes.sender}>{notification?.achiever_name}</div>
      </div>
      <div className={classes.message_container}>
        <div className={classes.message}>{notification?.message}</div>
        <div></div>
      </div>
      <div className={classes.time}>{notification?.notified_at}</div>
    </div>
  );
};

export default NotificationCard;
