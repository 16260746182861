import React from 'react';
import { computeStep } from '../../utils/helpers';

export const PartialCircleProgressBar = ({
  progress,
  radius,
  strokeWidth,
  color,
  dashedCircle = false,
}: any) => {
  const diameter = radius * 2;
  const modifiedProgress = progress * 0.72 + computeStep(progress); // Since 28% length of whole circle is hidden which is below part. So, I just reduced a step 28%. Now, 1 === 0.72(i.e. 1 - 0.28) and started it from 14th point....
  const circumference = diameter * Math.PI;
  const progressPercentage = Math.min(Math.max(modifiedProgress, 0), 100); // Limit progress to 0% to 90%
  const progressValue = (progressPercentage / 100) * circumference;
  const remainingValue = circumference - progressValue;

  return (
    <div>
      <svg height={160} width={200}>
        <circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="transparent"
          stroke="#31313c"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="transparent"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={
            dashedCircle ? '1,10' : `${progressValue} ${remainingValue}`
          }
          strokeDashoffset="485"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};
