import React from 'react';
import Modal from '../common/Modal/Modal';
import Button from '../common/Button/Button';
import classes from './PdfReportModal.module.scss';
import { Input } from '../common/Input/Input';

const PdfReportModal = ({
  isOpen = false,
  handleCopy,
  handleOpenInTab,
  setIsOpen = () => {},
  reportUrl,
}: {
  isOpen: boolean;
  handleCopy?: () => void;
  handleOpenInTab?: () => void;
  setIsOpen?: (value: any) => void;
  reportUrl?: string;
}) => {
  return (
    <>
      <Modal closeButton={false} show={isOpen}>
        <div className={classes.title}>PDF Report</div>
        <div className={classes.text}>
          <Input
            inputClassName={classes.input}
            type="text"
            value={reportUrl}
            onChange={() => {}}
            disabled={true}
          />
        </div>
        <div className={classes.btns_container}>
          <Button
            onClick={handleCopy}
            containerClassName={classes.copy_btn}
            text="Copy link"
          />
          <Button
            onClick={handleOpenInTab}
            containerClassName={classes.open_btn}
            text="Open link"
          />
        </div>
      </Modal>
    </>
  );
};

export default PdfReportModal;
