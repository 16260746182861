import { createSlice } from '@reduxjs/toolkit';
import { fetchAllNotificationsAsync } from '../actions/notifications.action';

const initialState = {
  isLoading: false,
  error: '',
  notifications: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetNotifications: () => initialState,
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllNotificationsAsync.pending, (state, action) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(fetchAllNotificationsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notifications = action.payload;
      })
      .addCase(fetchAllNotificationsAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetNotifications, setNotifications } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
