import React from 'react';
import classes from './SummaryRow.module.scss';

const SummaryRow = (props: any) => {
  const { description, amount, isTotalRow, isDifferenceRow, amount_color } =
    props;

  if (isTotalRow) {
    return (
      <div className={classes.main}>
        <div className={classes.description_total_row}>{description}</div>
        <div className={classes.amount_total_row}>{amount}</div>
      </div>
    );
  } else if (isDifferenceRow) {
    return (
      <div className={classes.main}>
        <div className={classes.description_diff_row}>{description}</div>
        <div
          className={classes.amount_diff_row}
          style={{ color: amount_color }}
        >
          {amount}
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.main}>
        <div className={classes.description}>{description}</div>
        <div className={classes.amount} style={{ color: amount_color }}>
          {amount}
        </div>
      </div>
    );
  }
};

export default SummaryRow;
