import classes from './Footer.module.scss';
import homeImage from '../../assets/png/home.png';
import vectorImage from '../../assets/png/vector.png';
import salesImage from '../../assets/png/sales.png';
import messageImage from '../../assets/png/message.png';
import { NavLink } from 'react-router-dom';
const Footer = (): JSX.Element => {
  const list = [
    { src: homeImage, to: '/home' },
    { src: vectorImage, to: '/stats' },
    { src: salesImage, to: '/stats-store' },
  ];
  return (
    <>
      <div className={classes.container}>
        <div className={classes.link_container}>
          {list?.map((item) => {
            return (
              <>
                <NavLink
                  to={item?.to}
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.nav_link} ${classes.active_item}`
                      : classes.nav_link
                  }
                >
                  <img className={classes.img} src={item?.src} />
                </NavLink>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Footer;
