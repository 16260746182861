import React, { useEffect } from 'react';
import { Input } from '../../components/common/Input/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../components/common/Button/Button';
import classes from './OneToOneSalesAmountModal.module.scss';

const OneToOneSalesAmountModal = ({
  onSubmit,
  setOpen,
  defaultValue,
}: {
  onSubmit: (values: any) => void;
  setOpen: (data: boolean) => void;
  defaultValue?: number | undefined;
}) => {
  const formik = useFormik({
    initialValues: {
      amount: defaultValue || '',
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .min(1, 'Value should be grater than 0')
        .required('Amount is required'),
    }),
    onSubmit: onSubmit,
  });

  return (
    <>
      <div className={classes.container}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <Input
            error={
              formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : ''
            }
            onBlur={formik.handleBlur('amount')}
            onChange={formik.handleChange('amount')}
            value={formik.values.amount}
            label="Amount"
            type="number"
          />
          <div className={classes.btns_container}>
            <Button type="submit" text="Save" />
            <Button
              onClick={() => setOpen(false)}
              buttonClassName={classes.cancel_btn}
              text="Cancel"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default OneToOneSalesAmountModal;
