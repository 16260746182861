import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from './router/Routes';
import UpdateNotification from './UpdateNotification'; // Import the component

const App = (): JSX.Element => {
  return (
    <div>
      <Routes />
      <UpdateNotification /> {/* Include the UpdateNotification component */}
    </div>
  );
};

export default App;
