import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import classes from "./Layout.module.scss";
const Layout = (): JSX.Element => {
  return (
    <>
      <div className={classes.container}>
        <main className={classes.outlet}>
          <Outlet />
        </main>
        <footer className={classes.footer}>
          <Footer />
        </footer>
      </div>
    </>
  );
};
export default Layout;
