import React from 'react';

const ArrownLeftSvg = ({
  className,
  onClick,
  width = '11',
  height = '18',
  isDisabled = false,
}: any) => {
  return (
    <div
      className={className}
      style={{
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        opacity: isDisabled ? '0.5' : '1',
      }}
      onClick={isDisabled ? () => {} : onClick}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 11 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.53711 17L2.24422 9.70711C1.85369 9.31658 1.85369 8.68342 2.24422 8.29289L9.53711 1"
          stroke="#E0E0E6"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};

export default ArrownLeftSvg;
