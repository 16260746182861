import React from 'react';
import classes from './ReactSelect.module.scss';
import Select from 'react-select';
import { relative } from 'path';
import { ISelectProps } from '../../../models/common/ISelectProps';
import ArrownDownSvg from '../../../assets/svg/ArrowDown';
import SelectDropDownSvg from '../../../assets/svg/SelectDropDownSvg';

const ReactSelect = ({
  options,
  onChange,
  value,
  defaultValue,
  mainClassName,
  controlStyles,
  menuStyles,
  ...rest
}: ISelectProps) => {
  let mainClass = classes.main;
  if (mainClassName) {
    mainClass = `${mainClass} ${mainClassName}`;
  }
  const modifiedOptions = options.map((option: any) => {
    return {
      value: option?.id,
      label: option?.name,
    };
  });

  const modifiedValue = modifiedOptions
    .filter((option: any) => option?.value === value)
    .map((option: any) => ({
      value: option.value,
      label: option.label.slice(0, 10),
    }));

  return (
    <div className={mainClass}>
      <Select
        options={modifiedOptions}
        value={modifiedValue}
        onChange={onChange}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            cursor: 'pointer',
            border: '1px solid rgba(255, 255, 255, 0.15)',
            borderRadius: '16px',
            backgroundColor: '#3d3d46',
            boxShadow: 'none',
            minHeight: '0px',
            height: '32px',
            '&:hover': {
              borderColor: 'rgba(255, 255, 255, 0.15)',
            },
            ...controlStyles,
          }),

          indicatorSeparator: () => ({
            width: '0px',
          }),
          dropdownIndicator: () => ({
            color: 'white',
            paddingRight: '15px',
            paddingBottom: '3px',
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0px 0px 0px 10px',
          }),
          menu: () => ({
            backgroundColor: '#3d3d46',
            borderRadius: '20px',
            width: '175px',
            position: 'absolute',
            left: '-30px',
            ...menuStyles,
          }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            overflowY: 'scroll',
            maxHeight: '150px',
            fontSize: '13px',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '10px',
            backgroundColor: state.isSelected ? 'darkgrey' : '',
            '&:hover': {
              backgroundColor: 'darkgrey',
            },
          }),
          singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: 'white',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '600',
          }),
          input: (baseStyles, state) => ({
            ...baseStyles,
            margin: '0px',
          }),
        }}
        components={{
          DropdownIndicator: () => {
            return (
              <div style={{ padding: '0px 15px 2px 0px' }}>
                <SelectDropDownSvg />
              </div>
            );
          },
        }}
        {...rest}
      />
    </div>
  );
};

export default ReactSelect;
