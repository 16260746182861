import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCountrySalesTarget } from '../../services/general.services';

export const getCountrySalesTargetAsync = createAsyncThunk<any, any, any>(
  'goals/getCountrySalesTargetAsync',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await getCountrySalesTarget(params);
      if (response) {
        return response?.data?.sales_target;
      }
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export const previousMonthCountrySalesTargetAsync = createAsyncThunk<
  any,
  any,
  any
>(
  'goals/previousMonthCountrySalesTargetAsyncc',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await getCountrySalesTarget(params);
      if (response) {
        return response?.data?.sales_target;
      }
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);
