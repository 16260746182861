import React from 'react';
import classes from './DateCarousal.module.scss';
import ArrownRightSvg from '../../assets/svg/ArrowRightSvg';
import ArrownLeftSvg from '../../assets/svg/ArrowLeftSvg';
import { IDateCarousalProps } from '../../models/common/IDateCarousalProps';

const DateCarousal = (props: IDateCarousalProps) => {
  const { onClickNext, onClickBack, date, dateClassName } = props;
  let dateClass = classes.date;
  if (dateClassName) {
    dateClass = `${dateClass} ${dateClassName}`;
  }
  return (
    <div className={classes.container}>
      <div className={classes.back_btn} onClick={onClickBack}>
        <ArrownLeftSvg />
      </div>
      <div className={dateClass}>{date}</div>
      <div className={classes.next_btn} onClick={onClickNext}>
        <ArrownRightSvg />
      </div>
    </div>
  );
};

export default DateCarousal;
