import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getProductGoalsStatsService } from "../../services/general.services";

export const getProductGoalsStatsAsync = createAsyncThunk<any, any, any>(
  "stats/getProductGoalsStatsAsync",
  async (params: string, { rejectWithValue }) => {
    try {
      const response = await getProductGoalsStatsService(params); // Assuming fetchUser returns a Promise
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error); // Assuming the user data is in response.data
    }
  }
);
