import classes from './Login.module.scss';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import logo from '../../../assets/png/logo.png';
import Button from '../../../components/common/Button/Button';
import { Input } from '../../../components/common/Input/Input';
import * as Yup from 'yup';
import { passwordRegex } from '../../../utils/constants';
import { useState } from 'react';
import { setUser } from '../../../redux/reducers/userReducer';
import { loginService } from '../../../services/general.services';
import { toast } from 'react-toastify';
import VisibilityOff from '../../../assets/svg/VisibilityOff';
import VisibilityOn from '../../../assets/svg/VisibilityOn';

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [type, setType] = useState('password');
  const [strength, setStrength] = useState('');
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Email is required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
    }),
    onSubmit: (values) => {
      loginService(values.email, values.password)
        .then((response) => {
          dispatch(setUser(response.data));
          toast.success('Login Successfully');
          navigate('/home');
        })
        .catch((error) => {
          toast.error(error?.response?.data?.email[0]);
        });
    },
  });

  const passwordValidator = (text: string) => {
    if (text?.length > 7) {
      if (passwordRegex?.veryStrongRegex.test(text)) {
        setStrength('Very Strong');
      } else if (passwordRegex?.strongRegex.test(text)) {
        setStrength('Strong');
      } else if (passwordRegex?.mediumRegex.test(text)) {
        setStrength('Medium');
      } else if (passwordRegex?.poorRegex.test(text)) {
        setStrength('Poor');
      }
    } else {
      setStrength('');
    }
  };

  const clickOffIconHandler = () => {
    setType('text');
  };

  const clickOnIconHandler = () => {
    setType('password');
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div className={classes.container}>
          <img src={logo} className={classes.logo} />
          <div className={classes.content_container}>
            <Input
              type="text"
              label="E-mail address"
              value={formik.values.email}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ''
              }
              onChange={formik.handleChange('email')}
              onBlur={formik.handleBlur('email')}
            />
            <Input
              type={type}
              label="Password"
              value={formik.values.password}
              error={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ''
              }
              onBlur={formik.handleBlur('password')}
              onChange={(event) => {
                passwordValidator(event.target.value);
                formik.setFieldValue('password', event.target.value);
              }}
              endIcon={
                type === 'text' ? (
                  <VisibilityOn onClick={clickOnIconHandler} />
                ) : (
                  <VisibilityOff onClick={clickOffIconHandler} />
                )
              }
            />
            <div className={classes.password_bar_container}>
              <div
                className={
                  strength === 'Poor'
                    ? classes.poor_password
                    : strength === 'Medium'
                    ? classes.medium_password
                    : strength === 'Strong'
                    ? classes.strong_password
                    : strength === 'Very Strong'
                    ? classes.very_strong_password
                    : ''
                }
              />
              <div
                className={
                  strength === 'Medium'
                    ? classes.medium_password
                    : strength === 'Strong'
                    ? classes.strong_password
                    : strength === 'Very Strong'
                    ? classes.very_strong_password
                    : ''
                }
              />
              <div
                className={
                  strength === 'Strong'
                    ? classes.strong_password
                    : strength === 'Very Strong'
                    ? classes.very_strong_password
                    : ''
                }
              />
              <div
                className={
                  strength === 'Very Strong' ? classes.very_strong_password : ''
                }
              />
            </div>
            <p className={classes.password_info}>
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </p>
            <Button
              type="submit"
              containerClassName={classes.get_started_btn_container}
              buttonClassName={classes.get_started_btn}
              text="Login"
            />
            {/* <div className={classes.accounts}>
              <p className={classes.already_account_heading}>
                Do you have already an account?
              </p>
              <Button buttonClassName={classes.account_btn} text="Sign In" />
            </div> */}
          </div>
        </div>
      </form>
    </>
  );
};
export default Login;
