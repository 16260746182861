import React from 'react';
import { Input } from '../../components/common/Input/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../components/common/Button/Button';
import classes from './OneToOneSalesModal.module.scss';

const OneToOneSalesModal = ({
  handleSelect,
  setOpen,
}: {
  handleSelect: (name: string, amount?: number) => void;
  setOpen: (data: boolean) => void;
}) => {
  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .min(1, 'Amount should be greater than 0')
        .required('Amount is required'),
    }),
    onSubmit: (values) => {
      handleSelect('one_to_one_sale', Number(values?.amount));
    },
  });
  return (
    <>
      <div className={classes.container}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <Input
            error={
              formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : ''
            }
            onBlur={formik.handleBlur('amount')}
            onChange={formik.handleChange('amount')}
            value={formik.values.amount}
            label="Amount"
            type="number"
          />
          <div className={classes.btns_container}>
            <Button
              containerClassName={classes.submit_btn}
              type="submit"
              text="Amount & Numbers"
            />
            <Button
              onClick={() => {
                handleSelect('one_to_one_sale', 0);
                setOpen(false);
              }}
              buttonClassName={classes.cancel_btn}
              containerClassName={classes.cancel_btn_container}
              text="Numbers only"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default OneToOneSalesModal;
